{
  "en": {
    "react": {
      "admin": {
        "accounts": {
          "curriculum_vitae": {
            "approach_required": "Approach are required for the English language",
            "approach_too_long": "Your bio is too long, maximum is 800 characters",
            "approach_too_short": "Your bio is too short, minimum is 400 characters",
            "copy_from_default_success": "Default CV has successfully been copied into commercial CV"
          },
          "edit": {
            "flash_success": "Your profile was updated successfully"
          }
        },
        "add_receivers": "Add receivers",
        "add_to_associate_list": "Add to the associates list",
        "add_to_batch": "Add to batch",
        "address": "Address",
        "addresses": "Business details",
        "affiliation": "Affiliation",
        "affiliations": "Affiliations",
        "are_you_sure_delete": "Are you sure you want to delete this %{referenceName}?",
        "associate_lists": {
          "add_coach_button": "Add coach",
          "add_coach_to_list_dialog_title": "Add coach to list",
          "add_to_list_failed": "Coach could not be added to the list",
          "add_to_list_success": "Coach was added to the list",
          "coach_count": "Coaches",
          "confirm_delete": "Delete this user from the list?",
          "create": "Create coach list",
          "edit": "Edit coach list",
          "intro": "Here you can create lists of coaches. These lists can be used in CoachDesks, the UNLOQ website and to quickly export all the CV's of a group of coaches.",
          "no_coaches_in_list": "There are no coaches in this list yet.",
          "title": "Coach lists"
        },
        "availability": "Availability",
        "avatars": "Profile photo",
        "bio": {
          "new": "User has updated their bio"
        },
        "btn": {
          "cancel": "Cancel",
          "confirm": "Confirm",
          "ok": "OK",
          "save": "Save"
        },
        "business_address": "Business Address",
        "business_details": "Business Details",
        "certification": "Certification",
        "certifications": "Certifications",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Only Excel files with a .xslx extension are supported",
              "placeholder": "Drop your Excel import file here"
            }
          }
        },
        "client_name": "Client name",
        "coaches": {
          "match_failed": "And error occurred and the client could not be matched to this coach",
          "match_success": "Client matched successfully"
        },
        "coaching_preferences": "Coaching preferences",
        "comments": {
          "disabled": "Notes are disabled for this program or you do not have permissions.",
          "email_explanation": "Ten minutes after a note has been placed by someone, an e-mail notification will be sent. Please note: every 10 minutes there is an automatic check for notifications, if there are any, 1 email message to notify will then be sent. This is to prevent spam.",
          "explanation": "Notes: internal communication between coach and L&D programme managers.",
          "new_messages_emails": "New notes emails",
          "shared": {
            "coach_only": "Coach only",
            "private": "L&D Programme managers only",
            "public": "Coaches and L&D Programme managers",
            "title": "Share with"
          },
          "subject": "Subject",
          "title": "Notes",
          "title_placeholder": "Write your notes about this client..."
        },
        "create_email_batch": "Create email batch",
        "create_email_batch_explanation": "With this feature you can email many users at the same time. First you have to decide if you want to use an existing template or continue without a template.<br /><br /> After that you can select all the users you want to email and add them to the batch. Then all that&apos;s left is to confirm that everything is correct and send out the batch.",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "The commercial CV differs from the default CV because it does not include NGO, non-profit or not-for-profit related activities. Advice: copy the default CV and then delete all activities that are not commercial.",
        "delete": "Delete",
        "download": "Download",
        "edit": "Edit",
        "education": "Education",
        "end_date": "End date",
        "files": {
          "preview_loading": "Preview loading"
        },
        "group_work": "Group Work",
        "help": "Help",
        "internal_only": "Internal use only, not publicly visible",
        "job_information": "Job Information",
        "job_titles": {
          "create": "Create job title",
          "edit": "Edit job title",
          "intro": "Here you can create Job titles. These can be used in Coach profile, in the Contract type section.",
          "title": "Job titles",
          "user_count": "Users"
        },
        "language": "Language",
        "languages": "Languages",
        "members": "Members",
        "name": "Name",
        "no_receivers_yet": "No receivers added yet",
        "none": "None",
        "not_applicable": "N/A",
        "pagination": {
          "next": "Next",
          "previous": "Previous"
        },
        "people_analytics": {
          "btn_copy_report_link": "Copy link to report",
          "btn_copy_report_link_hint": "Copy the URL to the report for you to share",
          "btn_download_report": "Download PDF",
          "btn_download_report_active": "Downloading...",
          "btn_download_report_hint": "Download the PDF file of your report",
          "btn_generate_report": "Generate report",
          "btn_generate_report_hint": "Generates new report",
          "btn_regenerate_report": "Re-generate report",
          "btn_regenerate_report_hint": "Re-generates the report, cannot be undone",
          "btn_send_report_link_dialog": "Send report by dialog",
          "btn_send_report_link_dialog_hint": "Sends the link to the report to your client in the dialogue",
          "btn_send_report_link_email": "Send report by email",
          "btn_send_report_link_email_active": "Sending...",
          "btn_send_report_link_email_hint": "Sends automatic email to the client with the link to report",
          "generate_report_error": "There was a problem with displaying this report",
          "generate_report_prompt": "Start with generating a report",
          "report_generated_at": "This report was generated at %{date}",
          "report_is_loading": "Loading generated report",
          "report_opened_by_client_at": "Client opened their report at %{date}"
        },
        "personal_information": "Personal information",
        "preview": "Preview",
        "preview_and_send": "Preview & Send",
        "profile_photo": "Profile Photo",
        "program_archived_warning": "This programme has been archived",
        "program_manager_officer": "Programme Manager/Officer",
        "programs": {
          "fields": {
            "archived": "Archived",
            "days_after_month_closure": "Days after month closure",
            "description": "Description",
            "enable_notes": "Enable notes",
            "end_date": "End date",
            "learning_path": "Learning path",
            "max_reminder_days": "Max reminder days",
            "minutes_per_client": "Minutes per client",
            "name": "Name",
            "questionnaire": "Questionnaire",
            "reminder_days": "Reminder days",
            "start_date": "Start date",
            "title": "Title"
          },
          "helper_texts": {
            "days_after_month_closure": "Session logs closed (period in days that session logs can be modified after last day of month)",
            "max_reminder_days": "People Analytics: max number of days to remind",
            "minutes_per_client": "Maximum time available per client (in minutes)",
            "questionnaire": "Note: the questionnaire for People Analytics can only be configured once.",
            "reminder_days": "People Analytics: number of days after next reminder e-mail"
          }
        },
        "prompt": "Approve or reject",
        "publication": "Publication",
        "publications": "Publications",
        "questionnaires": {
          "assignments": "Assignments",
          "label": "Questionnaires"
        },
        "receivers": "Receivers",
        "reject": "Reject",
        "rematch": "Rematch",
        "rematch_client": "Rematch client",
        "remove": "Remove",
        "remove_receiver": "Remove receiver",
        "reply": "Reply",
        "save": "Save",
        "send_pdf_reports": "Send PDF Reports",
        "send_pdf_reports_failed": "Could not send PDF reports, not all participants have completed the questionnaires",
        "send_pdf_reports_success": "PDF reports sent successfully",
        "short_bio": "Short bio",
        "toastrs": {
          "copied": "Copied!",
          "sent": "Sent!"
        },
        "unloq_only_contract_fields": {
          "body": "The fields below are managed by UNLOQ and are <b>NOT</b> visible and editable by the associates themselves. These fields are used in the associate selection procedure.",
          "title": "Fields managed by UNLOQ"
        },
        "untranslated": "Missing translations",
        "use_template": "Do you want to a template?",
        "validation": {
          "date_of_birth": {
            "not_adult": "You must provide a date of birth that is at least 16 years old"
          },
          "end_date_after_start_date": "End date must be later than start date",
          "linkedin": {
            "invalid": "Must be a valid LinkedIn URL"
          },
          "start_date_invalid": "Start date is not valid",
          "start_date_required": "Start date is required"
        },
        "work_experience": "Work Experience",
        "work_experience_duration": "Total years and hours of your coaching experience"
      },
      "associate_lists": {
        "add_failed": "Failed to add to the associates list",
        "added": "Added to the associates list"
      },
      "clients": {
        "rematch_failed": "Client could not be rematched, please contact support",
        "rematch_success": "Client rematched successfully"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Name account holder",
          "address_line": "Address",
          "bank_account_number": "Bank account number",
          "bank_identification": "Bank identification (BIC/SWIFT)",
          "bank_name": "Bank name",
          "city": "City",
          "company": "Company name",
          "country": "Country",
          "helper_texts": {
            "bank_account_number": "IBAN or other bank account number",
            "bank_identification": "Required if account number is not IBAN",
            "chamber_of_commerce": "Chamber of Commerce"
          },
          "house_number": "House number",
          "region": "Region",
          "zip_code": "Zip code"
        },
        "all": "All registered coaches",
        "associate_status": {
          "active": "Active",
          "label": "Status",
          "none": "No status",
          "offboard": "Offboard",
          "on_hold": "On hold"
        },
        "availability": "Availability",
        "avatars": {
          "approved": "Your new profile photo has been approved",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "There is no avatar",
            "upload": "Upload new avatar"
          },
          "not_approved_yet": "Your new profile photo hasn't been approved yet. We will review it as soon as possible",
          "photo": {
            "label": "Profile photo",
            "no_photo": "There is no profile photo"
          },
          "preview": "Preview",
          "rejected": "Your new profile photo has been rejected, please upload another",
          "upload": "Upload new profile photo",
          "uploaded": "New photo was uploaded, we will review it as soon as possible"
        },
        "bio": {
          "approved": "Your new bio has been approved",
          "not_approved_yet": "Your new bio hasn't been approved yet",
          "rejected": "Your new bio has been rejected, please write another",
          "updated": "Your bio has been updated and is awaiting approval"
        },
        "both": "Both",
        "cards_view": "The cards view",
        "chamber_of_commerce": "Business register number",
        "coach_type": "Associate type",
        "coach_types": {
          "a": "Senior Coach & Trainer",
          "t": "Senior Trainer & Facilitator"
        },
        "coaching_level": "Level coaching",
        "coaching_levels": {
          "executive": "Executive",
          "leadership_coaching": "Leadership coaching",
          "top_executive": "Top executive",
          "young_talent_professional": "Young talent professional"
        },
        "commercial_cv": "Commercial CV",
        "contract_remarks": "Remarks",
        "contract_type": "Contract type",
        "contracts": {
          "core_team": "Core team member",
          "fixed_hours": "Associate with fixed hours",
          "project_basis": "Associate on project basis"
        },
        "core_team": "Core team",
        "cv": {
          "affiliations": "Affiliations",
          "approach": "Approach",
          "branch_experiences": "Branch experiences",
          "certifications": "Certifications",
          "confirm_copy_cv": "Are you sure you want to copy all information from your default CV?<br /><br />Please note <b>YOU CAN ONLY DO THIS ONCE</b>, after which the button will disappear!",
          "contract_type": "Contract type",
          "copy_default_cv": "Copy your Default CV?",
          "copy_from_default_button": "Copy from default CV",
          "education": "Education",
          "examples": {
            "affiliation": {
              "acronym": "Example: EMCC",
              "name": "Example: EMCC Global",
              "number": "Example: 100"
            },
            "certification": {
              "end_date": "Example: Date until certification was valid (empty = indefinitely)",
              "institution": "Example: International Coaching Federation",
              "name": "Example: ICF Professional Certified Coach",
              "start_year": "Example: Year from which the certification holds",
              "topic": "Example: Coaching Course"
            },
            "education": {
              "end_date": "Example: Date until education was valid (empty = indefinitely)",
              "field_of_study": "Example: Clinical Psychology",
              "school": "Example: Harvard",
              "specialisation": "Example: Group dynamics",
              "start_year": "Example: Year from which the education holds"
            },
            "publication": {
              "authors": "Example: Doe, J. /  Doe. J. & Doedel, K.P.",
              "journal_title": "Example: Journal of Awareness",
              "medium": "Example: Television",
              "page_range": "Example: 201-220",
              "publisher": "Example: Routledge",
              "publisher_city": "Example: New York",
              "title": "Example: Self-recognition in everyday life",
              "url": "Example: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Example: Guest at The Late Show with Stephen Colbert",
              "volume_number": "Example: 5(2)",
              "year": "Example: (2022)"
            },
            "publications": {
              "type": "Choose the type of the publication"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acronym",
              "add": "Add another affiliation",
              "name": "Name",
              "number": "Affiliated Number"
            },
            "approach": "Short bio",
            "approach_current": "Current bio",
            "approach_helper_text": "Please write your job bio in English",
            "certification": {
              "add": "Add another certification",
              "end_date": "End date",
              "institution": "Issuing organization",
              "level": "Level",
              "name": "Certificate name",
              "start_year": "Start year",
              "topic": "Topic / Theme"
            },
            "certification_dropzone": "Drop your diploma / certification / testimonial files here",
            "certification_dropzone_helpertext": "Only PDF, PNG or JPG files can be uploaded",
            "education": {
              "add": "Add another education",
              "degree": "Degree",
              "end_date": "End date",
              "field_of_study": "Field of study",
              "school": "School",
              "specialisation": "Specialisation",
              "start_year": "Start year"
            },
            "occupations": "Occupations",
            "publication": {
              "add": "Add another publication",
              "article": "Article",
              "authors": "Author(s)",
              "book": "Book",
              "choose": "Choose publication type",
              "journal_title": "Journal name",
              "medium": "Medium",
              "page_range": "Page range",
              "publisher": "Publisher",
              "publisher_city": "Publisher's branch City",
              "title": "Title",
              "title_article": "Article title",
              "title_book": "Book title",
              "url": "Internet link",
              "various": "Various",
              "volume_number": "Volume nr, (Issue nr)",
              "year": "Year"
            },
            "publication_type": "Publication type",
            "specializations": "Specializations",
            "work_experience": {
              "add": "Add another work experience",
              "branch_experience": "Branch experiences",
              "company_name": "Company name",
              "description": "Work description",
              "end_date": "End date (empty = indefinitely)",
              "hours_of_experience": "Coaching hours",
              "role": "Role / title",
              "start_date": "Start date",
              "years_of_experience": "Years of coaching experience"
            }
          },
          "headers": {
            "certifications": "Educations / Certifications"
          },
          "help": {
            "avatars": {
              "help": "Show examples of good profile photos"
            }
          },
          "intro": {
            "affiliations": "List all professional associations you are affiliated to.",
            "availability": "Here you can manage your availability and travel preferences.",
            "certifications": "List here all your Licenses & Certificates for methods, approaches, assessments, tests and other matters related to learning, development and guidance activities. These should be your (professionally oriented) education, training, courses and workshops with an official certificate / licence / hallmark.",
            "contract_type": "This section is for internal use and is maintained by a Programme Manager. You cannot make adjustments yourself here.",
            "education": "List here all your educations and training courses that you have followed (including those you have not completed). These should be your officially government-recognised Educations/Qualifications/Degrees.",
            "publications": "List all publications you have written or contributed to. Please note, for uniform display we use the international APA rules. For each field we provide an example, use these to correctly fill in each field.",
            "work_experience": "Write your total amount of years and hours of your coaching experience. List your relevant work experience here, not only related to coaching & training but all your work experience."
          },
          "job_experience": "Job & work experience",
          "job_information": "Job information",
          "level_coaching": "Coaching level",
          "level_ecoaching": "E-coaching level",
          "professions": "Occupations",
          "publications": "Publications",
          "sectors": "Sectors",
          "specializations": "Specializations",
          "work_experiences": "Work experiences"
        },
        "date_of_birth": "Date of birth",
        "default_cv": "Default CV",
        "ecoaching_level": "Level ecoaching",
        "ecoaching_levels": {
          "level_i": "Level I",
          "level_ii": "Level II",
          "level_iii": "Level III",
          "none": "None"
        },
        "first_name": "First name",
        "group_work": {
          "add": "Add another group work experience",
          "select": "Select type of group work"
        },
        "group_work_location": {
          "label": "Online / Offline"
        },
        "group_work_locations": {
          "both": "Both",
          "offline": "Offline",
          "online": "Online"
        },
        "group_work_type": {
          "label": "Design / Delivery"
        },
        "group_work_types": {
          "both": "Both",
          "delivery": "Delivery",
          "design": "Design"
        },
        "hours_of_experience": "The total amount of hours you have coached others",
        "id_number": "ID number (passport of other ID)",
        "intro": {
          "addresses": "Please provide your business details below. Please note that only if you do <b>not</b> have an IBAN bank account number is it mandatory to fill in the 'BIC/SWIFT' field as well.",
          "avatars": "Please upload your profile photo. We are looking for powerful portraits where the people take centre stage. A clean look & feel without losing the warmth of the human aspect. The photos are also characterised by depth of field and the use of natural light.<br /><br />Please keep in mind we are looking for clean photos with not much bright colours or distracting objects in the background.<br /><br />Think of your LinkedIn picture, but a more relaxed version. Your picture should showcase who you are without forgetting this is a business environment. Ask yourself: which picture would you post on your LinkedIn vs. what would you post while on holidays?<br /><br />Try avoiding: Shorts, Sleeveless or flashy tops, Sandals<br /><br />The photo that you upload here will be displayed on the ”Our Team” page on the UNLOQ website and (if applicable) on the website pages of client CoachDesks.<br /><br /> <b>Note:</b> once you upload, the photo will need to be approved by UNLOQ before it becomes visible on the UNLOQ website and CoachDesks and as avatar within Campus. As soon as approval is complete this photo will appear in your profile.",
          "bio": "Please write a short bio about yourself. This bio will be added to your CV and will also be visible on the <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Our Team</a> page on the UNLOQ website and (if applicable) on the CoachDesks.<br /><br />The following requirements are set:<ul><li>Min 400 characters</li><li>Max 800 characters</li><li>Written in third person</li><li>Written in English</li></ul><i>EXAMPLE: Marcel has 15 years of coaching experience in leadership development and transition processes. He is interested in topics related to diversity, equity and inclusion and his practical approach is based on Eastern and Western psychology. Clients and peers say he is open-minded and flexible, gets to the point quickly and has a keen sense of what is needed.</i><br /><br /> <b>Note:</b> once you update your bio, it will need to be approved by UNLOQ before it becomes visible on the UNLOQ website and CoachDesks. As soon as approval is complete this bio will appear in our profile.",
          "bio_no_cv_yet": "Please provide information about your current job first before writing up your short bio.",
          "group_work": "Please list your experience and preference with different kinds of group work here. You can specify for each type of group work if you prefer delivering offline, online or both and if you have experience in designing or delivering or both.",
          "job_information": "Please provide information about your current job",
          "languages": "Please add all the languages that you speak along with the proficiency level.",
          "personal_information": "In this section of your profile you can enter all your basic personal information. Please note that some of this information will be visible on the <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Our Team\"</a> page on the UNLOQ website and on the CoachDesks. The fields marked as \"internal use only\" will not be publicly visible."
        },
        "job_title": {
          "select": "Job title"
        },
        "languages": {
          "add": "Add another language",
          "select": "Select language",
          "select_level": "Select language level"
        },
        "last_name": "Last name",
        "linkedin": "LinkedIn",
        "list_view": "list view",
        "multiple_answers_possible": "Multiple answers possible",
        "nationalities": "Nationalities",
        "native_background": "Native background / roots",
        "organization_name": "Organization name",
        "phone_number": "Phone number",
        "place_of_birth": "Place of Birth",
        "profile": "Profile",
        "remarks": {
          "add": "Add New Remark",
          "add_button": "Add Remark",
          "added": "Remark added successfully",
          "all_remarks": "All remarks",
          "complaint_remark": "Complaint",
          "complaint_remarks": "Complaints",
          "compliment_remark": "Compliment",
          "compliment_remarks": "Compliments",
          "delete_conformation": "Are you sure you want to delete this remark?",
          "deleted": "Remark deleted successfully",
          "edit": "Edit Remark",
          "general_remark": "General remark",
          "general_remarks": "General remarks",
          "intro": "Here you can see and write remarks about coaches.",
          "no_remarks": "There is no %{remark_type} yet. You can add one by clicking the 'Add New Remark' button below.",
          "title": "Remarks",
          "update_button": "Update Remark",
          "updated": "Remark updated successfully"
        },
        "residence_country": "Residence country",
        "search_in": "Search in",
        "secondary_phone_number": "Secondary phone number",
        "sectors": {
          "humanitarian": "Humanitarian",
          "private_sector": "Private",
          "public_sector": "Public"
        },
        "select_one_answer": "Select one answer",
        "timezone": "Timezone",
        "training_level": "Level facilitation",
        "training_levels": {
          "level_i": "Level I",
          "level_ii": "Level II",
          "level_iii": "Level III",
          "none": "None"
        },
        "travel": {
          "label": "Travel",
          "no_travel": "No",
          "yes_to_conflict": "Yes, also to conflict zones",
          "yes_travel": "Yes"
        },
        "unloq": "UNLOQ associates",
        "unloq_only": "UNLOQ associates only?",
        "years_of_experience": "The total amount of years you have been coaching"
      },
      "filters": {
        "branch_experiences": "Branch experiences",
        "certifications": "Certifications",
        "countries": "Countries",
        "hours_of_experience": "Minimum hours of experience",
        "job_title": "Job title",
        "languages": "Languages",
        "miscellaneous": "Miscellaneous",
        "name_or_email": "Name or email",
        "organization": "Organization",
        "professions": "Occupations",
        "programs": "Programs",
        "remarks": "Remarks",
        "specializations": "Specializations",
        "timezones": "Timezones",
        "years_of_experience": "Minimum years of experience"
      },
      "select": {
        "associate_list": "Select associate list",
        "coach": "Select coach",
        "program": "Select programme"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Welcome to the profile edit page!",
            "text_2": "In this tab your main information should be filled in.",
            "text_3": "The information you enter here will be used to match you to a client and to generate your curriculum vitae."
          },
          "step_commercial_cv": {
            "text_1": "The <b>%{commercial_cv}</b> tab is similar to the Default CV tab except that the information you enter here is meant for commercial customers specifically."
          },
          "step_copy": {
            "text_1": "You can use the <b>%{button_name}</b> button if no commercial CV has been created yet.",
            "text_2": "The button copies all the information from your default CV after which you can change or remove certain parts."
          },
          "step_default_cv": {
            "text_1": "In this tab your default curriculum vitae is composed.",
            "text_2": "Please fill in this data as much as possible, so UNLOQ will be able to better match your abilities to our clients.",
            "text_3": "We also use this data to automatically generate CV documents.",
            "text_4": "The generated CV documents will only be shared internally or with our customers."
          },
          "step_help": {
            "text_1": "This button opens up this help tour again. So, if you would like some explanations later on, please click on this button."
          }
        }
      }
    }
  },
  "es": {
    "react": {
      "admin": {
        "accounts": {
          "curriculum_vitae": {
            "approach_required": "Aproximación son necesarios para el idioma Inglés",
            "approach_too_long": "Su biografía es demasiado larga, el máximo son 800 caracteres",
            "approach_too_short": "Su biografía es demasiado corta, el mínimo son 400 caracteres",
            "copy_from_default_success": "El CV por defecto se ha copiado correctamente en el CV comercial"
          },
          "edit": {
            "flash_success": "Su perfil se ha actualizado correctamente"
          }
        },
        "add_receivers": "Añadir receptores",
        "add_to_associate_list": "Añadir a la lista de asociados",
        "add_to_batch": "Añadir al lote",
        "address": "Dirección",
        "addresses": "Datos comerciales",
        "affiliation": "Afiliación",
        "affiliations": "Afiliaciones",
        "are_you_sure_delete": "¿Está seguro de que desea eliminar este %{referenceName}?",
        "associate_lists": {
          "add_coach_button": "Añadir entrenador",
          "add_coach_to_list_dialog_title": "Añadir entrenador a la lista",
          "add_to_list_failed": "El entrenador no pudo ser añadido a la lista",
          "add_to_list_success": "El entrenador se ha añadido a la lista",
          "coach_count": "Entrenadores",
          "confirm_delete": "¿Suprimir este usuario de la lista?",
          "create": "Crear lista de entrenadores",
          "edit": "Editar la lista de entrenadores",
          "intro": "Aquí puedes crear listas de entrenadores. Estas listas se pueden utilizar en CoachDesks, en el sitio web de UNLOQ y para exportar rápidamente todos los CV de un grupo de entrenadores.",
          "no_coaches_in_list": "Aún no hay entrenadores en esta lista.",
          "title": "Listas de entrenadores"
        },
        "availability": "Disponibilidad",
        "avatars": "Foto de perfil",
        "bio": {
          "new": "El usuario ha actualizado su biografía"
        },
        "btn": {
          "cancel": "Cancelar",
          "confirm": "Confirmar",
          "ok": "Ok",
          "save": "Guardar"
        },
        "business_address": "Dirección profesional",
        "business_details": "Detalles de la empresa",
        "certification": "Certificación",
        "certifications": "Certificaciones",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Sólo se admiten archivos Excel con extensión .xslx",
              "placeholder": "Envíe aquí su archivo de importación de Excel"
            }
          }
        },
        "client_name": "Nombre del cliente",
        "coaches": {
          "match_failed": "Se ha producido un error y no se ha podido asignar el cliente a este entrenador",
          "match_success": "Cliente emparejado correctamente"
        },
        "coaching_preferences": "Preferencias de los entrenadores",
        "comments": {
          "disabled": "Las notas están desactivadas para este programa o no tiene permisos.",
          "email_explanation": "Diez minutos después de que alguien haya puesto una nota, se enviará una notificación por correo electrónico. Nota: cada 10 minutos se realiza una comprobación automática de notificaciones; si hay alguna, se enviará un mensaje de correo electrónico para notificarla. Esto es para evitar el spam.",
          "explanation": "Notas: comunicación interna entre el tutor y los responsables del programa de I+D.",
          "new_messages_emails": "Nuevos correos de notas",
          "shared": {
            "coach_only": "Sólo entrenador",
            "private": "Sólo gestores de programas de I+D",
            "public": "Entrenadores y directores de programas de I+D",
            "title": "Compartir con"
          },
          "subject": "Asunto",
          "title": "Notas",
          "title_placeholder": "Escribe tus notas sobre este cliente..."
        },
        "create_email_batch": "Crear lote de correo electrónico",
        "create_email_batch_explanation": "Con esta función puedes enviar correos electrónicos a muchos usuarios al mismo tiempo. Primero tienes que decidir si quieres usar una plantilla existente o continuar sin plantilla.<br /><br /> Después puedes seleccionar todos los usuarios a los que quieres enviar un correo electrónico y añadirlos al lote. Después sólo queda confirmar que todo está correcto y enviar el lote.",
        "curriculum_vitae": "Curriculum vitae",
        "curriculum_vitae_intro": "El CV comercial difiere del CV por defecto porque no incluye actividades relacionadas con ONG, organizaciones sin ánimo de lucro o sin ánimo de lucro. Consejo: copie el CV por defecto y luego elimine todas las actividades que no sean comerciales.",
        "delete": "Borrar",
        "download": "Descargar",
        "edit": "Editar",
        "education": "Educación",
        "end_date": "Fecha final",
        "files": {
          "preview_loading": "Carga previa"
        },
        "group_work": "Trabajo en grupo",
        "help": "Ayuda",
        "internal_only": "Sólo para uso interno, no visible públicamente",
        "job_information": "Información sobre el puesto",
        "job_titles": {
          "create": "Crear puesto de trabajo",
          "edit": "Editar título del puesto",
          "intro": "Aquí puede crear Títulos de trabajo. Estos se pueden utilizar en el perfil de Coach, en la sección Tipo de contrato.",
          "title": "Ofertas de empleo",
          "user_count": "Usuarios"
        },
        "language": "Idioma",
        "languages": "Idiomas",
        "members": "Miembros",
        "name": "Nombre",
        "no_receivers_yet": "Aún no se han añadido receptores",
        "none": "Ninguno",
        "not_applicable": "N/A",
        "pagination": {
          "next": "Siguiente",
          "previous": "Anterior"
        },
        "people_analytics": {
          "btn_copy_report_link": "Copiar enlace para informar",
          "btn_copy_report_link_hint": "Copie la URL del informe para compartirlo",
          "btn_download_report": "Descargar PDF",
          "btn_download_report_active": "Descargando...",
          "btn_download_report_hint": "Descargue el archivo PDF de su informe",
          "btn_generate_report": "Generar informe",
          "btn_generate_report_hint": "Genera un nuevo informe",
          "btn_regenerate_report": "Volver a generar el informe",
          "btn_regenerate_report_hint": "Vuelve a generar el informe, no se puede deshacer",
          "btn_send_report_link_dialog": "Enviar informe por diálogo",
          "btn_send_report_link_dialog_hint": "Envía el enlace al informe a tu cliente en el diálogo",
          "btn_send_report_link_email": "Enviar informe por correo electrónico",
          "btn_send_report_link_email_active": "Enviando...",
          "btn_send_report_link_email_hint": "Envía un correo electrónico automático al cliente con el enlace al informe",
          "generate_report_error": "Ha habido un problema al visualizar este informe",
          "generate_report_prompt": "Empiece por generar un informe",
          "report_generated_at": "Este informe se generó en %{date}",
          "report_is_loading": "Carga del informe generado",
          "report_opened_by_client_at": "El cliente abrió su informe en %{date}"
        },
        "personal_information": "Información personal",
        "preview": "Vista previa",
        "preview_and_send": "Vista previa y envío",
        "profile_photo": "Foto de perfil",
        "program_archived_warning": "Este programa ha sido archivado",
        "program_manager_officer": "Gestor de programas/Oficial",
        "programs": {
          "fields": {
            "archived": "Archivado",
            "days_after_month_closure": "Días después del cierre del mes",
            "description": "Descripción",
            "enable_notes": "Habilitar notas",
            "end_date": "Fecha de finalización",
            "learning_path": "Formación",
            "max_reminder_days": "Días máximos de recordatorio",
            "minutes_per_client": "Minutos por cliente",
            "name": "Nombre",
            "questionnaire": "Cuestionario",
            "reminder_days": "Días de recordatorio",
            "start_date": "Fecha de inicio",
            "title": "Título"
          },
          "helper_texts": {
            "days_after_month_closure": "Registros de sesión cerrados (periodo en días que se pueden modificar los registros de sesión después del último día del mes)",
            "max_reminder_days": "People Analytics: número máximo de días para recordar",
            "minutes_per_client": "Tiempo máximo disponible por cliente (en minutos)",
            "questionnaire": "Nota: el cuestionario para People Analytics sólo puede configurarse una vez.",
            "reminder_days": "People Analytics: número de días transcurridos desde el siguiente correo electrónico de recordatorio"
          }
        },
        "prompt": "Aprobar o rechazar",
        "publication": "Publicación",
        "publications": "Publicaciones",
        "questionnaires": {
          "assignments": "Asignaciones",
          "label": "Cuestionarios"
        },
        "receivers": "Receptores",
        "reject": "Rechazar",
        "rematch": "Revancha",
        "rematch_client": "Cliente de revancha",
        "remove": "Eliminar",
        "remove_receiver": "Eliminar receptor",
        "reply": "Respuesta",
        "save": "Guardar",
        "send_pdf_reports": "Enviar informes en PDF",
        "send_pdf_reports_failed": "No se pueden enviar informes en PDF, no todos los participantes han rellenado los cuestionarios",
        "send_pdf_reports_success": "Informes PDF enviados correctamente",
        "short_bio": "Breve biografía",
        "toastrs": {
          "copied": "¡Copiado!",
          "sent": "¡Enviado!"
        },
        "unloq_only_contract_fields": {
          "body": "Los siguientes campos son gestionados por UNLOQ y <b>NO</b> son visibles y editables por los propios asociados. Estos campos se utilizan en el procedimiento de selección de asociados.",
          "title": "Campos gestionados por UNLOQ"
        },
        "untranslated": "Traducciones pendientes",
        "use_template": "¿Quiere una plantilla?",
        "validation": {
          "date_of_birth": {
            "not_adult": "Debe facilitar una fecha de nacimiento de al menos 16 años"
          },
          "end_date_after_start_date": "La fecha de finalización debe ser posterior a la de inicio",
          "linkedin": {
            "invalid": "Debe ser una URL válida de LinkedIn"
          },
          "start_date_invalid": "La fecha de inicio no es válida",
          "start_date_required": "Se requiere fecha de inicio"
        },
        "work_experience": "Experiencia laboral",
        "work_experience_duration": "Total de años y horas de experiencia en coaching"
      },
      "associate_lists": {
        "add_failed": "Fallo al añadir a la lista de asociados",
        "added": "Añadido a la lista de asociados"
      },
      "clients": {
        "rematch_failed": "El cliente no ha podido ser reenviado, por favor contacte con soporte",
        "rematch_success": "Cliente reemparejado con éxito"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Nombre del titular de la cuenta",
          "address_line": "Dirección",
          "bank_account_number": "Número de cuenta bancaria",
          "bank_identification": "Identificación bancaria (BIC/SWIFT)",
          "bank_name": "Nombre del banco",
          "city": "Ciudad",
          "company": "Nombre de la empresa",
          "country": "País",
          "helper_texts": {
            "bank_account_number": "IBAN u otro número de cuenta bancaria",
            "bank_identification": "Obligatorio si el número de cuenta no es el IBAN",
            "chamber_of_commerce": "Cámara de Comercio"
          },
          "house_number": "Número de casa",
          "region": "Región",
          "zip_code": "Código postal"
        },
        "all": "Todos los entrenadores registrados",
        "associate_status": {
          "active": "Activo",
          "label": "Estado",
          "none": "Sin estado",
          "offboard": "Fuera de borda",
          "on_hold": "En espera"
        },
        "availability": "Disponibilidad",
        "avatars": {
          "approved": "Tu nueva foto de perfil ha sido aprobada",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "No hay avatar",
            "upload": "Subir nuevo avatar"
          },
          "not_approved_yet": "Tu nueva foto de perfil aún no ha sido aprobada. La revisaremos lo antes posible",
          "photo": {
            "label": "Foto de perfil",
            "no_photo": "No hay foto de perfil"
          },
          "preview": "Vista previa",
          "rejected": "Tu nueva foto de perfil ha sido rechazada, por favor sube otra",
          "upload": "Subir una nueva foto de perfil",
          "uploaded": "Se ha subido una nueva foto, la revisaremos lo antes posible"
        },
        "bio": {
          "approved": "Su nueva biografía ha sido aprobada",
          "not_approved_yet": "Tu nueva biografía aún no ha sido aprobada",
          "rejected": "Su nueva biografía ha sido rechazada, por favor escriba otra",
          "updated": "Su biografía ha sido actualizada y está pendiente de aprobación"
        },
        "both": "Ambos",
        "cards_view": "La vista de las tarjetas",
        "chamber_of_commerce": "Número de registro mercantil",
        "coach_type": "Tipo de asociado",
        "coach_types": {
          "a": "Entrenador principal y formador",
          "t": "Formador Senior y Facilitador"
        },
        "coaching_level": "Entrenamiento de nivel",
        "coaching_levels": {
          "executive": "Ejecutivo",
          "leadership_coaching": "Coaching de liderazgo",
          "top_executive": "Alto ejecutivo",
          "young_talent_professional": "Profesional de talento joven"
        },
        "commercial_cv": "CV comercial",
        "contract_remarks": "Observaciones",
        "contract_type": "Tipo de contrato",
        "contracts": {
          "core_team": "Miembro del equipo central",
          "fixed_hours": "Asociado con horario fijo",
          "project_basis": "Asociado por proyecto"
        },
        "core_team": "Equipo principal",
        "cv": {
          "affiliations": "Afiliaciones",
          "approach": "Acérquese a",
          "branch_experiences": "Experiencias de las sucursales",
          "certifications": "Certificaciones",
          "confirm_copy_cv": "¿Estás seguro de que quieres copiar toda la información de tu CV por defecto?<br /><br />Ten en cuenta que <b>SÓLO PUEDES HACER ESTO UNA VEZ</b>, ¡después el botón desaparecerá!",
          "contract_type": "Tipo de contrato",
          "copy_default_cv": "¿Copiar su CV por defecto?",
          "copy_from_default_button": "Copiar del CV por defecto",
          "education": "Educación",
          "examples": {
            "affiliation": {
              "acronym": "Ejemplo: EMCC",
              "name": "Ejemplo: EMCC Global",
              "number": "Ejemplo: 100"
            },
            "certification": {
              "end_date": "Ejemplo: Fecha hasta la que fue válida la certificación (vacío = indefinidamente)",
              "institution": "Ejemplo: Federación Internacional de Coaching",
              "name": "Ejemplo: Coach profesional certificado por la ICF",
              "start_year": "Ejemplo: Año de validez de la certificación",
              "topic": "Ejemplo: Curso de coaching"
            },
            "education": {
              "end_date": "Ejemplo: Fecha hasta la que la educación era válida (vacío = indefinidamente)",
              "field_of_study": "Ejemplo: Psicología clínica",
              "school": "Ejemplo: Harvard",
              "specialisation": "Ejemplo: Dinámica de grupo",
              "start_year": "Ejemplo: Año a partir del cual se imparte la enseñanza"
            },
            "publication": {
              "authors": "Ejemplo: Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Ejemplo: Diario de la conciencia",
              "medium": "Ejemplo: Televisión",
              "page_range": "Ejemplo: 201-220",
              "publisher": "Ejemplo: Routledge",
              "publisher_city": "Ejemplo: Nueva York",
              "title": "Ejemplo: El autorreconocimiento en la vida cotidiana",
              "url": "Ejemplo: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Ejemplo: Invitado en The Late Show with Stephen Colbert.",
              "volume_number": "Ejemplo: 5(2)",
              "year": "Ejemplo: (2022)"
            },
            "publications": {
              "type": "Elija el tipo de publicación"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acrónimo",
              "add": "Añadir otra afiliación",
              "name": "Nombre",
              "number": "Número de afiliado"
            },
            "approach": "Breve biografía",
            "approach_current": "Biografía actual",
            "approach_helper_text": "Escriba su biografía profesional en inglés",
            "certification": {
              "add": "Añadir otra certificación",
              "end_date": "Fecha final",
              "institution": "Organización emisora",
              "level": "Nivel",
              "name": "Nombre del certificado",
              "start_year": "Año de inicio",
              "topic": "Asunto / Tema"
            },
            "certification_dropzone": "Envíe aquí sus archivos de diplomas / certificaciones / testimonios",
            "certification_dropzone_helpertext": "Sólo se pueden cargar archivos PDF, PNG o JPG",
            "education": {
              "add": "Añadir otra educación",
              "degree": "Titulación",
              "end_date": "Fecha final",
              "field_of_study": "Ámbito de estudio",
              "school": "Escuela",
              "specialisation": "Especialización",
              "start_year": "Año de inicio"
            },
            "occupations": "Ocupaciones",
            "publication": {
              "add": "Añadir otra publicación",
              "article": "Artículo",
              "authors": "Autor(es)",
              "book": "Libro",
              "choose": "Elija el tipo de publicación",
              "journal_title": "Nombre de la revista",
              "medium": "Medio",
              "page_range": "Páginas",
              "publisher": "Editorial",
              "publisher_city": "Sucursal del editor Ciudad",
              "title": "Título",
              "title_article": "Título del artículo",
              "title_book": "Título del libro",
              "url": "Enlace de Internet",
              "various": "Varios",
              "volume_number": "Número de volumen (Número de edición)",
              "year": "Año"
            },
            "publication_type": "Tipo de publicación",
            "specializations": "Especializaciones",
            "work_experience": {
              "add": "Añadir otra experiencia laboral",
              "branch_experience": "Experiencias de las sucursales",
              "company_name": "Nombre de la empresa",
              "description": "Descripción del trabajo",
              "end_date": "Fecha de finalización (vacío = indefinidamente)",
              "hours_of_experience": "Horas de coaching",
              "role": "Función / título",
              "start_date": "Fecha de inicio",
              "years_of_experience": "Años de experiencia en coaching"
            }
          },
          "headers": {
            "certifications": "Formación / Certificaciones"
          },
          "help": {
            "avatars": {
              "help": "Consejos fotográficos"
            }
          },
          "intro": {
            "affiliations": "Enumere todas las asociaciones profesionales a las que está afiliado.",
            "availability": "Aquí puede gestionar su disponibilidad y sus preferencias de viaje.",
            "certifications": "Enumere aquí todas sus Licencias y Certificados para métodos, enfoques, evaluaciones, pruebas y otros asuntos relacionados con actividades de aprendizaje, desarrollo y orientación. Debe tratarse de su educación (orientada profesionalmente), formación, cursos y talleres con un certificado / licencia / sello oficial.",
            "contract_type": "Esta sección es de uso interno y está gestionada por un responsable de programa. Aquí no puede realizar ajustes usted mismo.",
            "education": "Enumere aquí todos los estudios y cursos de formación que ha seguido (incluidos los que no ha completado). Debe tratarse de estudios, titulaciones o diplomas reconocidos oficialmente por el gobierno.",
            "publications": "Enumere todas las publicaciones en las que haya escrito o colaborado. Tenga en cuenta que, para una presentación uniforme, utilizamos las normas APA internacionales. Para cada campo proporcionamos un ejemplo, utilícelos para rellenar correctamente cada campo.",
            "work_experience": "Enumere aquí su experiencia laboral relevante, no sólo la relacionada con el coaching y la formación, sino toda su experiencia laboral."
          },
          "job_experience": "Empleo y experiencia laboral",
          "job_information": "Información sobre el puesto",
          "level_coaching": "Nivel de entrenador",
          "level_ecoaching": "Nivel de e-coaching",
          "professions": "Ocupaciones",
          "publications": "Publicaciones",
          "sectors": "Sectores",
          "specializations": "Especializaciones",
          "work_experiences": "Experiencias laborales"
        },
        "date_of_birth": "Fecha de nacimiento",
        "default_cv": "CV por defecto",
        "ecoaching_level": "Nivel ecoaching",
        "ecoaching_levels": {
          "level_i": "Nivel I",
          "level_ii": "Nivel II",
          "level_iii": "Nivel III",
          "none": "Ninguno"
        },
        "first_name": "Nombre",
        "group_work": {
          "add": "Añadir otra experiencia de trabajo en grupo",
          "select": "Seleccione el tipo de trabajo en grupo"
        },
        "group_work_location": {
          "label": "En línea / Fuera de línea"
        },
        "group_work_locations": {
          "both": "Ambos",
          "offline": "Fuera de línea",
          "online": "En línea"
        },
        "group_work_type": {
          "label": "Diseño / Entrega"
        },
        "group_work_types": {
          "both": "Ambos",
          "delivery": "Entrega",
          "design": "Diseño"
        },
        "hours_of_experience": "El número total de horas que ha entrenado a otras personas",
        "id_number": "Número de identificación (pasaporte u otro documento de identidad)",
        "intro": {
          "addresses": "Indique a continuación los datos de su empresa. Tenga en cuenta que solo es obligatorio rellenar el campo \"BIC/SWIFT\" si no dispone de un número de cuenta bancaria IBAN.",
          "avatars": "Cargue su foto de perfil. Buscamos retratos impactantes en los que las personas sean el centro de atención. Un look & feel limpio sin perder la calidez del aspecto humano. Las fotos también se caracterizan por la profundidad de campo y el uso de luz natural.<br /><br />Ten en cuenta que buscamos fotos limpias, sin muchos colores brillantes ni objetos que distraigan en el fondo.<br /><br />Piensa en tu foto de LinkedIn, pero en una versión más relajada. Tu foto debe mostrar quién eres sin olvidar que este es un entorno de negocios. Pregúntate: ¿qué foto publicarías en tu LinkedIn frente a la que publicarías estando de vacaciones?<br /><br />Trata de evitar: Pantalones cortos, camisetas sin mangas o llamativas, sandalias<br /><br />La foto que subas aquí se mostrará en la página \"Nuestro equipo\" del sitio web de UNLOQ y (si procede) en las páginas web de los CoachDesks de los clientes.<br /><br /><b>Nota:</b> una vez que la subas, la foto tendrá que ser aprobada por UNLOQ antes de que sea visible en el sitio web de UNLOQ y en los CoachDesks y como avatar dentro del Campus. Tan pronto como se complete la aprobación, esta foto aparecerá en tu perfil.",
          "bio": "Por favor, escriba una breve biografía sobre usted. Esta biografía se añadirá a su CV y también será visible en la página <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Nuestro equipo</a> del sitio web de UNLOQ y (si procede) en los CoachDesks.<br /><br />Se establecen los siguientes requisitos:<ul><li>Mín 400 caracteres</li><li>Máx 800 caracteres</li><li>Escrito en tercera persona</li><li>Escrito en inglés</li></ul><i>EJEMPLO: Marcel tiene 15 años de experiencia como coach en procesos de desarrollo y transición de liderazgo. Le interesan los temas relacionados con la diversidad, la equidad y la inclusión y su enfoque práctico se basa en la psicología oriental y occidental. Clientes y compañeros dicen que es abierto y flexible, que va al grano rápidamente y que tiene un gran sentido de lo que se necesita.</i><br /><br /><b>Nota:</b> una vez que actualices tu biografía, tendrá que ser aprobada por UNLOQ antes de que sea visible en el sitio web de UNLOQ y en CoachDesks. Tan pronto como se complete la aprobación, esta biografía aparecerá en nuestro perfil.",
          "bio_no_cv_yet": "Por favor, facilite primero información sobre su trabajo actual antes de redactar su breve biografía.",
          "group_work": "Indique aquí su experiencia y sus preferencias en distintos tipos de trabajo en grupo. Puede especificar para cada tipo de trabajo en grupo si prefiere realizarlo fuera de línea, en línea o ambos y si tiene experiencia en el diseño, la realización o ambos.",
          "job_information": "Proporcione información sobre su trabajo actual",
          "languages": "Por favor, añada todos los idiomas que habla junto con el nivel de competencia.",
          "personal_information": "En esta sección de tu perfil puedes introducir toda tu información personal básica. Ten en cuenta que parte de esta información será visible en la página <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Nuestro equipo\"</a> del sitio web de UNLOQ y en los CoachDesks. Los campos marcados como \"sólo para uso interno\" no serán visibles públicamente."
        },
        "job_title": {
          "select": "Puesto"
        },
        "languages": {
          "add": "Agregar otro idioma",
          "select": "Seleccionar idioma",
          "select_level": "Seleccionar nivel de idioma"
        },
        "last_name": "Apellido",
        "linkedin": "LinkedIn",
        "list_view": "vista de lista",
        "multiple_answers_possible": "Múltiples respuestas posibles",
        "nationalities": "Nacionalidades",
        "native_background": "Origen / raíces nativas",
        "organization_name": "Nombre de la organización",
        "phone_number": "Número de teléfono",
        "place_of_birth": "Lugar de nacimiento",
        "profile": "Perfil",
        "remarks": {
          "add": "Añadir nuevo comentario",
          "add_button": "Añadir comentario",
          "added": "Observación añadida con éxito",
          "all_remarks": "Todos los comentarios",
          "complaint_remark": "Queja",
          "complaint_remarks": "Reclamaciones",
          "compliment_remark": "Elogio",
          "compliment_remarks": "Cortesía de",
          "delete_conformation": "¿Está seguro de que desea eliminar este comentario?",
          "deleted": "Comentario eliminado correctamente",
          "edit": "Editar comentario",
          "general_remark": "Comentario general",
          "general_remarks": "Observaciones generales",
          "intro": "Aquí puede ver y escribir comentarios sobre los coaches.",
          "no_remarks": "Aún no hay %{remark_type}. Puede añadir uno haciendo clic en el botón \"Añadir nuevo comentario\".",
          "title": "Comentarios",
          "update_button": "Actualizar comentario",
          "updated": "Comentario actualizado correctamente"
        },
        "residence_country": "País de residencia",
        "search_in": "Buscar en",
        "secondary_phone_number": "Número de teléfono secundario",
        "sectors": {
          "humanitarian": "Humanitario",
          "private_sector": "Privado",
          "public_sector": "Público"
        },
        "select_one_answer": "Seleccione una respuesta",
        "timezone": "Zona horaria",
        "training_level": "Facilitación del nivel",
        "training_levels": {
          "level_i": "Nivel I",
          "level_ii": "Nivel II",
          "level_iii": "Nivel III",
          "none": "Ninguno"
        },
        "travel": {
          "label": "Viajes",
          "no_travel": "No",
          "yes_to_conflict": "Sí, también a zonas conflictivas",
          "yes_travel": "Sí"
        },
        "unloq": "Asociados UNLOQ",
        "unloq_only": "¿Sólo asociados UNLOQ?",
        "years_of_experience": "La cantidad total de años que ha estado entrenando"
      },
      "filters": {
        "branch_experiences": "Experiencias de las sucursales",
        "certifications": "Certificaciones",
        "countries": "Países",
        "hours_of_experience": "Horas mínimas de experiencia",
        "job_title": "Puesto",
        "languages": "Idiomas",
        "miscellaneous": "Varios",
        "name_or_email": "Nombre o correo electrónico",
        "organization": "Organización",
        "professions": "Ocupaciones",
        "programs": "Programas",
        "remarks": "Observaciones",
        "specializations": "Especializaciones",
        "timezones": "Husos horarios",
        "years_of_experience": "Experiencia mínima"
      },
      "select": {
        "associate_list": "Seleccionar lista de asociados",
        "coach": "Seleccionar entrenador",
        "program": "Seleccionar programa"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "¡Bienvenido a la página de edición de perfil!",
            "text_2": "En esta pestaña debe rellenar sus datos principales.",
            "text_3": "La información que introduzca aquí se utilizará para emparejarlo con un cliente y generar su currículum vítae."
          },
          "step_commercial_cv": {
            "text_1": "La pestaña <b>%{commercial_cv}</b> es similar a la pestaña CV predeterminado, salvo que la información que se introduce aquí está destinada específicamente a los clientes comerciales."
          },
          "step_copy": {
            "text_1": "Puede utilizar el botón <b>%{button_name}</b> si aún no se ha creado ningún CV comercial.",
            "text_2": "El botón copia toda la información de su CV por defecto, tras lo cual puede cambiar o eliminar determinadas partes."
          },
          "step_default_cv": {
            "text_1": "En esta pestaña se compone su currículum vitae por defecto.",
            "text_2": "Por favor, rellena estos datos en la medida de lo posible, para que UNLOQ pueda adecuar mejor tus capacidades a las de nuestros clientes.",
            "text_3": "También utilizamos estos datos para generar automáticamente documentos de CV.",
            "text_4": "Los documentos de CV generados sólo se compartirán internamente o con nuestros clientes."
          },
          "step_help": {
            "text_1": "Este botón abre de nuevo este recorrido de ayuda. Así que, si desea algunas explicaciones más adelante, haga clic en este botón."
          }
        }
      }
    }
  },
  "fr": {
    "react": {
      "admin": {
        "accounts": {
          "curriculum_vitae": {
            "approach_required": "L'approbation est requise pour la langue anglaise",
            "approach_too_long": "Votre bio est trop longue, le maximum est de 800 caractères",
            "approach_too_short": "Votre biographie est trop courte, le minimum est de 400 caractères",
            "copy_from_default_success": "Le CV par défaut a été copié avec succès dans le CV commercial"
          },
          "edit": {
            "flash_success": "Votre profil a été mis à jour avec succès"
          }
        },
        "add_receivers": "Ajouter des récepteurs",
        "add_to_associate_list": "Ajouter à la liste des associés",
        "add_to_batch": "Ajouter au lot",
        "address": "Adresse",
        "addresses": "Informations commerciales",
        "affiliation": "Affiliation",
        "affiliations": "Affiliations",
        "are_you_sure_delete": "Êtes-vous sûr de vouloir supprimer cette %{referenceName} ?",
        "associate_lists": {
          "add_coach_button": "Ajouter un entraîneur",
          "add_coach_to_list_dialog_title": "Ajouter l'entraîneur à la liste",
          "add_to_list_failed": "L'entraîneur n'a pas pu être ajouté à la liste",
          "add_to_list_success": "L'entraîneur a été ajouté à la liste",
          "coach_count": "Entraîneurs",
          "confirm_delete": "Supprimer cet utilisateur de la liste ?",
          "create": "Créer une liste d'entraîneurs",
          "edit": "Modifier la liste des entraîneurs",
          "intro": "Ici, vous pouvez créer des listes de coachs. Ces listes peuvent être utilisées dans les CoachDesks, le site web UNLOQ et pour exporter rapidement tous les CV d'un groupe d'entraîneurs.",
          "no_coaches_in_list": "Il n'y a pas encore d'entraîneur dans cette liste.",
          "title": "Listes d'entraîneurs"
        },
        "availability": "Disponibilité",
        "avatars": "Photo de profil",
        "bio": {
          "new": "L'utilisateur a mis à jour sa bio"
        },
        "btn": {
          "cancel": "Annuler",
          "confirm": "Confirmer",
          "ok": "Ok",
          "save": "Économiser"
        },
        "business_address": "Adresse professionnelle",
        "business_details": "Détails de l'entreprise",
        "certification": "Certification",
        "certifications": "Certifications",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Seuls les fichiers Excel portant l'extension .xslx sont pris en charge",
              "placeholder": "Déposez votre fichier d'importation Excel ici"
            }
          }
        },
        "client_name": "Nom du client",
        "coaches": {
          "match_failed": "Une erreur s'est produite et le client n'a pas pu être mis en relation avec cet entraîneur",
          "match_success": "Le client a été apparié avec succès"
        },
        "coaching_preferences": "Préférences en matière de coaching",
        "comments": {
          "disabled": "Les notes sont désactivées pour ce programme ou vous n'avez pas les autorisations nécessaires.",
          "email_explanation": "Dix minutes après qu'une note a été placée par quelqu'un, une notification par courrier électronique est envoyée. Remarque : toutes les 10 minutes, un contrôle automatique des notifications est effectué. S'il y en a, un message électronique de notification est alors envoyé. Ceci afin d'éviter les spams.",
          "explanation": "Notes : communication interne entre le coach et les responsables du programme de L&D.",
          "new_messages_emails": "Nouveaux courriels de notes",
          "shared": {
            "coach_only": "Entraîneur uniquement",
            "private": "Uniquement les responsables de programmes de L&D",
            "public": "Coachs et responsables de programmes de L&D",
            "title": "Partager avec"
          },
          "subject": "Sujet",
          "title": "Notes",
          "title_placeholder": "Rédigez vos notes sur ce client..."
        },
        "create_email_batch": "Créer un lot d'e-mails",
        "create_email_batch_explanation": "Cette fonction vous permet d'envoyer des courriels à plusieurs utilisateurs en même temps. Vous devez d'abord décider si vous souhaitez utiliser un modèle existant ou continuer sans modèle.<br /><br /> Ensuite, vous pouvez sélectionner tous les utilisateurs que vous souhaitez envoyer par courrier électronique et les ajouter au lot. Il ne vous reste plus qu'à confirmer que tout est correct et à envoyer le lot.",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "Le CV commercial diffère du CV par défaut parce qu'il n'inclut pas les activités liées aux ONG, aux organisations à but non lucratif ou aux organisations à but non lucratif. Conseil : copiez le CV par défaut et supprimez toutes les activités qui ne sont pas commerciales.",
        "delete": "Supprimer",
        "download": "Télécharger",
        "edit": "Editer",
        "education": "L'éducation",
        "end_date": "Date de fin",
        "files": {
          "preview_loading": "Chargement de l'aperçu"
        },
        "group_work": "Travail en groupe",
        "help": "Aide",
        "internal_only": "Usage interne uniquement, non visible par le public",
        "job_information": "Informations sur l'emploi",
        "job_titles": {
          "create": "Créer un titre de poste",
          "edit": "Modifier l'intitulé du poste",
          "intro": "Ici, vous pouvez créer des titres de postes. Ceux-ci peuvent être utilisés dans le profil du coach, dans la section Type de contrat.",
          "title": "Titres des postes",
          "user_count": "Utilisateurs"
        },
        "language": "Langue",
        "languages": "Langues",
        "members": "Les membres",
        "name": "Nom",
        "no_receivers_yet": "Aucun récepteur n'a encore été ajouté",
        "none": "Aucun",
        "not_applicable": "N/A",
        "pagination": {
          "next": "Suivant",
          "previous": "Précédent"
        },
        "people_analytics": {
          "btn_copy_report_link": "Copier le lien vers le rapport",
          "btn_copy_report_link_hint": "Copier l'URL du rapport à partager",
          "btn_download_report": "Télécharger le PDF",
          "btn_download_report_active": "Téléchargement...",
          "btn_download_report_hint": "Télécharger le fichier PDF de votre rapport",
          "btn_generate_report": "Générer un rapport",
          "btn_generate_report_hint": "Génère un nouveau rapport",
          "btn_regenerate_report": "Générer à nouveau un rapport",
          "btn_regenerate_report_hint": "Génère à nouveau le rapport, ne peut être annulé",
          "btn_send_report_link_dialog": "Envoi d'un rapport par dialogue",
          "btn_send_report_link_dialog_hint": "Envoi du lien vers le rapport à votre client dans le dialogue",
          "btn_send_report_link_email": "Envoyer le rapport par courrier électronique",
          "btn_send_report_link_email_active": "Envoi...",
          "btn_send_report_link_email_hint": "Envoi automatique d'un courrier électronique au client avec le lien vers le rapport",
          "generate_report_error": "Il y a eu un problème d'affichage de ce rapport",
          "generate_report_prompt": "Commencez par générer un rapport",
          "report_generated_at": "Ce rapport a été généré à %{date}",
          "report_is_loading": "Chargement du rapport généré",
          "report_opened_by_client_at": "Le client a ouvert son rapport à %{date}"
        },
        "personal_information": "Informations personnelles",
        "preview": "Avant-première",
        "preview_and_send": "Prévisualiser et envoyer",
        "profile_photo": "Photo de profil",
        "program_archived_warning": "Ce programme a été archivé",
        "program_manager_officer": "Responsable de programme/Officier",
        "programs": {
          "fields": {
            "archived": "Archivé",
            "days_after_month_closure": "Jours après la clôture du mois",
            "description": "Description de l'offre",
            "enable_notes": "Activer les notes",
            "end_date": "Date de fin",
            "learning_path": "Parcours d'apprentissage",
            "max_reminder_days": "Jours de rappel maximum",
            "minutes_per_client": "Minutes par client",
            "name": "Nom de l'entreprise",
            "questionnaire": "Questionnaire",
            "reminder_days": "Journées de rappel",
            "start_date": "Date de début",
            "title": "Titre de l'article"
          },
          "helper_texts": {
            "days_after_month_closure": "Journaux de session fermés (période en jours pendant laquelle les journaux de session peuvent être modifiés après le dernier jour du mois)",
            "max_reminder_days": "People Analytics : nombre maximum de jours de rappel",
            "minutes_per_client": "Temps maximum disponible par client (en minutes)",
            "questionnaire": "Note : le questionnaire pour People Analytics ne peut être configuré qu'une seule fois.",
            "reminder_days": "People Analytics : nombre de jours après le prochain e-mail de rappel"
          }
        },
        "prompt": "Approuver ou rejeter",
        "publication": "Publication",
        "publications": "Publications",
        "questionnaires": {
          "assignments": "Missions",
          "label": "Questionnaires"
        },
        "receivers": "Récepteurs",
        "reject": "Rejeter",
        "rematch": "Rematch",
        "rematch_client": "Client du match retour",
        "remove": "Retirer",
        "remove_receiver": "Supprimer le récepteur",
        "reply": "Répondre",
        "save": "Économiser",
        "send_pdf_reports": "Envoyer des rapports PDF",
        "send_pdf_reports_failed": "Impossible d'envoyer les rapports PDF, tous les participants n'ont pas rempli les questionnaires",
        "send_pdf_reports_success": "Rapports PDF envoyés avec succès",
        "short_bio": "Brève biographie",
        "toastrs": {
          "copied": "Copié !",
          "sent": "Envoyé !"
        },
        "unloq_only_contract_fields": {
          "body": "Les champs ci-dessous sont gérés par UNLOQ et ne sont <b>PAS</b> visibles et modifiables par les associés eux-mêmes. Ces champs sont utilisés dans la procédure de sélection des associés.",
          "title": "Champs gérés par UNLOQ"
        },
        "untranslated": "Traductions manquantes",
        "use_template": "Voulez-vous un modèle ?",
        "validation": {
          "date_of_birth": {
            "not_adult": "Vous devez fournir une date de naissance d'au moins 16 ans"
          },
          "end_date_after_start_date": "La date de fin doit être postérieure à la date de début",
          "linkedin": {
            "invalid": "Doit être une URL LinkedIn valide"
          },
          "start_date_invalid": "La date de début n'est pas valide",
          "start_date_required": "La date de début est requise"
        },
        "work_experience": "Expérience professionnelle",
        "work_experience_duration": "Nombre total d'années et d'heures d'expérience en coaching"
      },
      "associate_lists": {
        "add_failed": "Échec de l'ajout à la liste des associés",
        "added": "Ajouté à la liste des associés"
      },
      "clients": {
        "rematch_failed": "Le client n'a pas pu être remis en contact, veuillez contacter l'assistance",
        "rematch_success": "Le client a été réaffecté avec succès"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Nom du titulaire du compte",
          "address_line": "Adresse",
          "bank_account_number": "Numéro de compte bancaire",
          "bank_identification": "Identification de la banque (BIC/SWIFT)",
          "bank_name": "Nom de la banque",
          "city": "Ville",
          "company": "Nom de l'entreprise",
          "country": "Pays",
          "helper_texts": {
            "bank_account_number": "IBAN ou autre numéro de compte bancaire",
            "bank_identification": "Obligatoire si le numéro de compte n'est pas IBAN",
            "chamber_of_commerce": "Chambre de commerce"
          },
          "house_number": "Numéro de maison",
          "region": "Région",
          "zip_code": "Code postal"
        },
        "all": "Tous les entraîneurs inscrits",
        "associate_status": {
          "active": "Actif",
          "label": "Statut",
          "none": "Pas de statut",
          "offboard": "Hors bord",
          "on_hold": "En attente"
        },
        "availability": "Disponibilité",
        "avatars": {
          "approved": "Votre nouvelle photo de profil a été approuvée",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "Il n'y a pas d'avatar",
            "upload": "Télécharger un nouvel avatar"
          },
          "not_approved_yet": "Votre nouvelle photo de profil n'a pas encore été approuvée. Nous l'examinerons dès que possible",
          "photo": {
            "label": "Photo de profil",
            "no_photo": "Il n'y a pas de photo de profil"
          },
          "preview": "Avant-première",
          "rejected": "Votre nouvelle photo de profil a été rejetée, veuillez en télécharger une autre",
          "upload": "Télécharger une nouvelle photo de profil",
          "uploaded": "Une nouvelle photo a été téléchargée, nous l'examinerons dès que possible"
        },
        "bio": {
          "approved": "Votre nouvelle bio a été approuvée",
          "not_approved_yet": "Votre nouvelle bio n'a pas encore été approuvée",
          "rejected": "Votre nouvelle bio a été rejetée, veuillez en écrire une autre",
          "updated": "Votre biographie a été mise à jour et est en attente d'approbation"
        },
        "both": "Les deux",
        "cards_view": "La vue des cartes",
        "chamber_of_commerce": "Numéro de registre du commerce",
        "coach_type": "Type d'associé",
        "coach_types": {
          "a": "Entraîneur et formateur principal",
          "t": "Formateur principal et animateur"
        },
        "coaching_level": "Niveau de coaching",
        "coaching_levels": {
          "executive": "Cadre",
          "leadership_coaching": "Coaching en matière de leadership",
          "top_executive": "Cadre supérieur",
          "young_talent_professional": "Jeunes talents professionnels"
        },
        "commercial_cv": "CV commercial",
        "contract_remarks": "Remarques",
        "contract_type": "Type de contrat",
        "contracts": {
          "core_team": "Membre de l'équipe centrale",
          "fixed_hours": "Associé avec des heures fixes",
          "project_basis": "Associé sur la base d'un projet"
        },
        "core_team": "Équipe de base",
        "cv": {
          "affiliations": "Affiliations",
          "approach": "Approche",
          "branch_experiences": "Expériences de la branche",
          "certifications": "Certifications",
          "confirm_copy_cv": "Êtes-vous sûr de vouloir copier toutes les informations de votre CV par défaut ? <br /><br />Veuillez noter <b>VOUS NE POUVEZ LE FAIRE QU'UNE SEULE FOIS</b>, après quoi le bouton disparaîtra !",
          "contract_type": "Type de contrat",
          "copy_default_cv": "Copier votre CV par défaut ?",
          "copy_from_default_button": "Copie du CV par défaut",
          "education": "L'éducation",
          "examples": {
            "affiliation": {
              "acronym": "Exemple : EMCC",
              "name": "Exemple : EMCC Global",
              "number": "Exemple : 100"
            },
            "certification": {
              "end_date": "Exemple : Date jusqu'à laquelle la certification était valide (vide = indéfiniment)",
              "institution": "Exemple : Fédération internationale des entraîneurs",
              "name": "Exemple : Coach professionnel certifié ICF",
              "start_year": "Exemple : Année de délivrance de la certification",
              "topic": "Exemple : Cours de coaching"
            },
            "education": {
              "end_date": "Exemple : Date jusqu'à laquelle l'éducation était valable (vide = indéfiniment)",
              "field_of_study": "Exemple : Psychologie clinique",
              "school": "Exemple : Harvard",
              "specialisation": "Exemple : Dynamique de groupe",
              "start_year": "Exemple : Année à partir de laquelle l'enseignement est dispensé"
            },
            "publication": {
              "authors": "Exemple : Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Exemple : Journal de sensibilisation",
              "medium": "Exemple : La télévision",
              "page_range": "Exemple : 201-220",
              "publisher": "Exemple : Routledge",
              "publisher_city": "Exemple : New York",
              "title": "Exemple : Reconnaissance de soi dans la vie quotidienne",
              "url": "Exemple : https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Exemple : Invité au Late Show avec Stephen Colbert.",
              "volume_number": "Exemple : 5(2)",
              "year": "Exemple : (2022)"
            },
            "publications": {
              "type": "Choisir le type de publication"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acronyme",
              "add": "Ajouter une autre affiliation",
              "name": "Nom",
              "number": "Numéro d'affiliation"
            },
            "approach": "Brève biographie",
            "approach_current": "Biographie actuelle",
            "approach_helper_text": "Veuillez rédiger votre CV en anglais",
            "certification": {
              "add": "Ajouter une autre certification",
              "end_date": "Date de fin",
              "institution": "Organisme émetteur",
              "level": "Niveau",
              "name": "Nom du certificat",
              "start_year": "Année de démarrage",
              "topic": "Sujet / Thème"
            },
            "certification_dropzone": "Déposez ici vos fichiers de diplômes / certifications / témoignages",
            "certification_dropzone_helpertext": "Seuls les fichiers PDF, PNG ou JPG peuvent être téléchargés",
            "education": {
              "add": "Ajouter une autre éducation",
              "degree": "Diplôme",
              "end_date": "Date de fin",
              "field_of_study": "Domaine d'études",
              "school": "L'école",
              "specialisation": "Spécialisation",
              "start_year": "Année de démarrage"
            },
            "occupations": "Professions",
            "publication": {
              "add": "Ajouter une autre publication",
              "article": "Article",
              "authors": "Auteur(s)",
              "book": "Livre",
              "choose": "Choisir le type de publication",
              "journal_title": "Nom du journal",
              "medium": "Moyen",
              "page_range": "Gamme de pages",
              "publisher": "Éditeur",
              "publisher_city": "Branche de l'éditeur Ville",
              "title": "Titre",
              "title_article": "Titre de l'article",
              "title_book": "Titre du livre",
              "url": "Lien internet",
              "various": "Divers",
              "volume_number": "Numéro de volume (numéro d'édition)",
              "year": "Année"
            },
            "publication_type": "Type de publication",
            "specializations": "Spécialisations",
            "work_experience": {
              "add": "Ajouter une autre expérience professionnelle",
              "branch_experience": "Expériences de la branche",
              "company_name": "Nom de l'entreprise",
              "description": "Description du travail",
              "end_date": "Date de fin (vide = indéfiniment)",
              "hours_of_experience": "Heures de coaching",
              "role": "Rôle / titre",
              "start_date": "Date de début",
              "years_of_experience": "Des années d'expérience en coaching"
            }
          },
          "headers": {
            "certifications": "Formations / Certifications"
          },
          "help": {
            "avatars": {
              "help": "Conseils photo"
            }
          },
          "intro": {
            "affiliations": "Indiquez toutes les associations professionnelles auxquelles vous êtes affilié.",
            "availability": "Ici, vous pouvez gérer vos disponibilités et vos préférences de voyage.",
            "certifications": "Dressez ici la liste de vos licences et certificats pour les méthodes, approches, évaluations, tests et autres questions liées à l'apprentissage, au développement et aux activités d'orientation. Il doit s'agir de votre éducation, de votre formation, de vos cours et de vos ateliers (orientés professionnellement) avec un certificat / une licence / un sceau officiel.",
            "contract_type": "Cette section est à usage interne et est gérée par un gestionnaire de programme. Vous ne pouvez pas y apporter de modifications vous-même.",
            "education": "Dressez ici la liste de toutes les études et formations que vous avez suivies (y compris celles que vous n'avez pas achevées). Il doit s'agir de vos études/qualifications/diplômes officiellement reconnus par le gouvernement.",
            "publications": "Dressez la liste de toutes les publications auxquelles vous avez participé ou écrit. Veuillez noter que pour une présentation uniforme, nous utilisons les règles internationales de l'APA. Pour chaque champ, nous fournissons un exemple. Utilisez-le pour remplir correctement chaque champ.",
            "work_experience": "Indiquez ici votre expérience professionnelle pertinente, non seulement dans le domaine du coaching et de la formation, mais aussi dans tous les domaines."
          },
          "job_experience": "Emploi et expérience professionnelle",
          "job_information": "Informations sur l'emploi",
          "level_coaching": "Niveau d'entraînement",
          "level_ecoaching": "Niveau d'e-coaching",
          "professions": "Professions",
          "publications": "Publications",
          "sectors": "Secteurs d'activité",
          "specializations": "Spécialisations",
          "work_experiences": "Expériences professionnelles"
        },
        "date_of_birth": "Date de naissance",
        "default_cv": "CV par défaut",
        "ecoaching_level": "Niveau d'éco-aching",
        "ecoaching_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Aucun"
        },
        "first_name": "Prénom",
        "group_work": {
          "add": "Ajouter une autre expérience de travail en groupe",
          "select": "Sélectionner le type de travail en groupe"
        },
        "group_work_location": {
          "label": "En ligne / Hors ligne"
        },
        "group_work_locations": {
          "both": "Les deux",
          "offline": "Hors ligne",
          "online": "En ligne"
        },
        "group_work_type": {
          "label": "Conception / Livraison"
        },
        "group_work_types": {
          "both": "Les deux",
          "delivery": "Livraison",
          "design": "Conception"
        },
        "hours_of_experience": "Le nombre total d'heures pendant lesquelles vous avez coaché d'autres personnes",
        "id_number": "Numéro d'identification (passeport ou autre pièce d'identité)",
        "intro": {
          "addresses": "Veuillez fournir les détails de votre entreprise ci-dessous. Veuillez noter que le champ \"BIC/SWIFT\" n'est obligatoire que si vous ne disposez pas d'un numéro de compte bancaire IBAN.",
          "avatars": "Veuillez télécharger votre photo de profil. Nous recherchons des portraits puissants où les personnes occupent une place centrale. Les photos doivent être nettes, sans perdre la chaleur de l'aspect humain. Les photos sont également caractérisées par la profondeur de champ et l'utilisation de la lumière naturelle.<br /><br />Veuillez garder à l'esprit que nous recherchons des photos nettes, sans couleurs vives ni objets distrayants en arrière-plan.<br /><br />Pensez à votre photo LinkedIn, mais en version plus décontractée. Votre photo doit montrer qui vous êtes sans oublier qu'il s'agit d'un environnement professionnel. Posez-vous la question suivante : quelle photo afficheriez-vous sur votre LinkedIn par rapport à celle que vous afficheriez pendant vos vacances ? <br /><br />Essayez d'éviter : Shorts, hauts sans manches ou voyants, sandales<br /><br />La photo que vous téléchargez ici sera affichée sur la page \"Notre équipe\" du site web d'UNLOQ et (le cas échéant) sur les pages du site Web des CoachDesks des clients.<br /><br /> <b>Note:</b> une fois que vous avez téléchargé, la photo devra être approuvée par UNLOQ avant d'être visible sur le site Web d'UNLOQ et sur les CoachDesks et comme avatar dans Campus. Dès que l'approbation sera terminée, cette photo apparaîtra dans votre profil.",
          "bio": "Veuillez rédiger une courte biographie de vous-même. Cette biographie sera ajoutée à votre CV et sera également visible sur la page <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Notre équipe</a> du site web d'UNLOQ et (le cas échéant) sur les CoachDesks.<br /><br />Les exigences suivantes sont fixées:<ul><li>Min 400 caractères</li><li>Max 800 caractères</li><li>Rédigé à la troisième personne</li><li>Rédigé en anglais</li></ul><i>EXEMPLE : Marcel a 15 ans d'expérience de coaching dans le développement du leadership et les processus de transition. Il s'intéresse aux sujets liés à la diversité, à l'équité et à l'inclusion et son approche pratique est basée sur la psychologie orientale et occidentale. Ses clients et ses pairs disent qu'il est ouvert d'esprit et flexible, qu'il va rapidement au but et qu'il a un sens aigu de ce qui est nécessaire.</i><br /><br /><b>Note:</b> une fois que vous aurez mis à jour votre bio, elle devra être approuvée par UNLOQ avant d'être visible sur le site web d'UNLOQ et sur les CoachDesks. Dès que l'approbation sera terminée, cette biographie apparaîtra dans notre profil.",
          "bio_no_cv_yet": "Veuillez d'abord fournir des informations sur votre emploi actuel avant de rédiger votre courte biographie.",
          "group_work": "Veuillez indiquer ici votre expérience et vos préférences en ce qui concerne les différents types de travail de groupe. Vous pouvez préciser pour chaque type de travail de groupe si vous préférez le faire hors ligne, en ligne ou les deux et si vous avez de l'expérience dans la conception ou la réalisation ou les deux.",
          "job_information": "Veuillez fournir des informations sur votre emploi actuel",
          "languages": "Veuillez ajouter toutes les langues que vous parlez ainsi que leur niveau de compétence.",
          "personal_information": "Dans cette section de votre profil, vous pouvez entrer toutes vos informations personnelles de base. Veuillez noter que certaines de ces informations seront visibles sur la page <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Notre équipe\"</a> du site web UNLOQ et sur les CoachDesks. Les champs marqués comme \"usage interne uniquement\" ne seront pas visibles par le public."
        },
        "job_title": {
          "select": "Titre du poste"
        },
        "languages": {
          "add": "Ajouter une autre langue",
          "select": "Sélection de la langue",
          "select_level": "Sélectionner le niveau de langue"
        },
        "last_name": "Nom de famille",
        "linkedin": "LinkedIn",
        "list_view": "vue en liste",
        "multiple_answers_possible": "Plusieurs réponses possibles",
        "nationalities": "Nationalités",
        "native_background": "Origine / racines",
        "organization_name": "Nom de l'organisation",
        "phone_number": "Numéro de téléphone",
        "place_of_birth": "Lieu de naissance",
        "profile": "Profil",
        "remarks": {
          "add": "Ajouter une nouvelle remarque",
          "add_button": "Ajouter une remarque",
          "added": "Remarque ajoutée avec succès",
          "all_remarks": "Toutes les remarques",
          "complaint_remark": "Réclamation",
          "complaint_remarks": "Réclamations",
          "compliment_remark": "Compliment",
          "compliment_remarks": "Compliments",
          "delete_conformation": "Êtes-vous sûr de vouloir supprimer cette remarque ?",
          "deleted": "Remarque supprimée avec succès",
          "edit": "Modifier la remarque",
          "general_remark": "Remarque générale",
          "general_remarks": "Remarques générales",
          "intro": "Ici, vous pouvez voir et écrire des commentaires sur les coachs.",
          "no_remarks": "Il n'y a pas encore de %{remark_type}. Vous pouvez en ajouter une en cliquant sur le bouton \"Ajouter une nouvelle remarque\" ci-dessous.",
          "title": "Remarques",
          "update_button": "Mise à jour Remarque",
          "updated": "Remarque mise à jour avec succès"
        },
        "residence_country": "Pays de résidence",
        "search_in": "Rechercher dans",
        "secondary_phone_number": "Numéro de téléphone secondaire",
        "sectors": {
          "humanitarian": "Humanitaire",
          "private_sector": "Privé",
          "public_sector": "Public"
        },
        "select_one_answer": "Sélectionnez une réponse",
        "timezone": "Fuseau horaire",
        "training_level": "Facilitation des niveaux",
        "training_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Aucun"
        },
        "travel": {
          "label": "Voyage",
          "no_travel": "Non",
          "yes_to_conflict": "Oui, également dans les zones de conflit",
          "yes_travel": "Oui"
        },
        "unloq": "Associés d'UNLOQ",
        "unloq_only": "Uniquement les associés de l'UNLOQ ?",
        "years_of_experience": "Depuis combien d'années êtes-vous coach ?"
      },
      "filters": {
        "branch_experiences": "Expériences de la branche",
        "certifications": "Certifications",
        "countries": "Pays",
        "hours_of_experience": "Nombre minimum d'heures d'expérience",
        "job_title": "Titre du poste",
        "languages": "Langues",
        "miscellaneous": "Divers",
        "name_or_email": "Nom ou courriel",
        "organization": "Organisation",
        "professions": "Professions",
        "programs": "Les programmes",
        "remarks": "Remarques",
        "specializations": "Spécialisations",
        "timezones": "Fuseaux horaires",
        "years_of_experience": "Nombre minimum d'années d'expérience"
      },
      "select": {
        "associate_list": "Sélectionner la liste des associés",
        "coach": "Sélectionner l'entraîneur",
        "program": "Sélectionner le programme"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Bienvenue sur la page de modification du profil !",
            "text_2": "Dans cet onglet, vos principales informations doivent être renseignées.",
            "text_3": "Les informations que vous saisissez ici seront utilisées pour vous mettre en relation avec un client et pour créer votre curriculum vitae."
          },
          "step_commercial_cv": {
            "text_1": "L'onglet <b>%{commercial_cv}</b> est similaire à l'onglet CV par défaut, sauf que les informations que vous saisissez ici sont destinées spécifiquement aux clients commerciaux."
          },
          "step_copy": {
            "text_1": "Vous pouvez utiliser le bouton <b>%{button_name}</b> si aucun CV commercial n'a encore été créé.",
            "text_2": "Le bouton copie toutes les informations de votre CV par défaut, après quoi vous pouvez modifier ou supprimer certaines parties."
          },
          "step_default_cv": {
            "text_1": "Dans cet onglet, votre curriculum vitae par défaut est composé.",
            "text_2": "Veuillez remplir ces données autant que possible, afin qu'UNLOQ puisse mieux adapter vos compétences à nos clients.",
            "text_3": "Nous utilisons également ces données pour générer automatiquement des CV.",
            "text_4": "Les CV générés ne seront partagés qu'en interne ou avec nos clients."
          },
          "step_help": {
            "text_1": "Ce bouton permet d'ouvrir à nouveau ce tour d'aide. Si vous souhaitez obtenir des explications plus tard, cliquez sur ce bouton."
          }
        }
      }
    }
  },
  "nl": {
    "react": {
      "admin": {
        "accounts": {
          "curriculum_vitae": {
            "approach_required": "Benaderingen zijn vereist voor de Engelse taal",
            "approach_too_long": "Je bio is te lang, het maximum is 800 tekens",
            "approach_too_short": "Je bio is te kort, het minimum is 400 tekens",
            "copy_from_default_success": "Standaard CV is succesvol gekopieerd naar commercieel CV"
          },
          "edit": {
            "flash_success": "Je profiel is succesvol bijgewerkt"
          }
        },
        "add_receivers": "Ontvangers toevoegen",
        "add_to_associate_list": "Toevoegen aan de lijst met partners",
        "add_to_batch": "Toevoegen aan batch",
        "address": "Adres",
        "addresses": "Bedrijfsgegevens",
        "affiliation": "Aansluiting",
        "affiliations": "Affiliaties",
        "are_you_sure_delete": "Weet je zeker dat je deze %{referenceName} wilt verwijderen?",
        "associate_lists": {
          "add_coach_button": "Coach toevoegen",
          "add_coach_to_list_dialog_title": "Coach toevoegen aan lijst",
          "add_to_list_failed": "Coach kon niet worden toegevoegd aan de lijst",
          "add_to_list_success": "Coach werd toegevoegd aan de lijst",
          "coach_count": "Coaches",
          "confirm_delete": "Deze gebruiker uit de lijst verwijderen?",
          "create": "Coachlijst maken",
          "edit": "Coachlijst bewerken",
          "intro": "Hier kun je lijsten van coaches aanmaken. Deze lijsten kunnen gebruikt worden in CoachDesks, de UNLOQ website en om snel alle CV's van een groep coaches te exporteren.",
          "no_coaches_in_list": "Er staan nog geen coaches in deze lijst.",
          "title": "Coach lijsten"
        },
        "availability": "Beschikbaarheid",
        "avatars": "Profielfoto",
        "bio": {
          "new": "Gebruiker heeft zijn bio bijgewerkt"
        },
        "btn": {
          "cancel": "Annuleren",
          "confirm": "Bevestig",
          "ok": "Ok",
          "save": "Sla"
        },
        "business_address": "Adres",
        "business_details": "Bedrijfsgegevens",
        "certification": "Certificering",
        "certifications": "Certificeringen",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Alleen Excel-bestanden met de extensie .xslx worden ondersteund",
              "placeholder": "Drop je Excel-importbestand hier"
            }
          }
        },
        "client_name": "Naam klant",
        "coaches": {
          "match_failed": "Er is een fout opgetreden en de klant kan niet worden gekoppeld aan deze coach",
          "match_success": "Klant succesvol gekoppeld"
        },
        "coaching_preferences": "Voorkeuren voor coaching",
        "comments": {
          "disabled": "Notities zijn uitgeschakeld voor dit programma of u hebt geen rechten.",
          "email_explanation": "Tien minuten nadat iemand een notitie heeft geplaatst, wordt er een e-mailbericht verstuurd. Let op: elke 10 minuten wordt er automatisch gecontroleerd of er meldingen zijn. Als die er zijn, wordt er 1 e-mailbericht verstuurd. Dit is om spam te voorkomen.",
          "explanation": "Opmerkingen: interne communicatie tussen de coach en de managers van het L&D-programma.",
          "new_messages_emails": "Nieuwe e-mails met notities",
          "shared": {
            "coach_only": "Alleen coach",
            "private": "Alleen voor managers van L&D-programma's",
            "public": "Coaches en managers van L&D-programma's",
            "title": "Deel met"
          },
          "subject": "Onderwerp",
          "title": "Opmerkingen",
          "title_placeholder": "Schrijf je aantekeningen over deze klant..."
        },
        "create_email_batch": "Batch e-mail maken",
        "create_email_batch_explanation": "Met deze functie kunt u veel gebruikers tegelijkertijd e-mailen. Eerst moet u beslissen of u een bestaande sjabloon wilt gebruiken of verder wilt gaan zonder sjabloon.<br /><br /> Daarna kunt u alle gebruikers selecteren die u wilt e-mailen en ze toevoegen aan de batch. Daarna hoef je alleen nog maar te bevestigen dat alles correct is en de batch te verzenden.",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "Het commerciële CV verschilt van het standaard CV omdat het geen NGO, non-profit of non-profit gerelateerde activiteiten bevat. Advies: kopieer het standaard CV en verwijder vervolgens alle activiteiten die niet commercieel zijn.",
        "delete": "Verwijder",
        "download": "Downloaden",
        "edit": "Bewerk",
        "education": "Onderwijs",
        "end_date": "Einddatum",
        "files": {
          "preview_loading": "Voorbeeld laden"
        },
        "group_work": "Groepswerk",
        "help": "Help",
        "internal_only": "Alleen voor intern gebruik, niet publiekelijk zichtbaar",
        "job_information": "Functie-informatie",
        "job_titles": {
          "create": "Functie creëren",
          "edit": "Functie bewerken",
          "intro": "Hier kun je functietitels creëren. Deze kunnen worden gebruikt in Coach profiel, in de Contracttype sectie.",
          "title": "Functiebenamingen",
          "user_count": "Gebruikers"
        },
        "language": "Taal",
        "languages": "Talen",
        "members": "Leden",
        "name": "Naam",
        "no_receivers_yet": "Nog geen ontvangers toegevoegd",
        "none": "Geen",
        "not_applicable": "N.V.T",
        "pagination": {
          "next": "Volgende",
          "previous": "Vorige"
        },
        "people_analytics": {
          "btn_copy_report_link": "Link naar rapport kopiëren",
          "btn_copy_report_link_hint": "Kopieer de URL naar het rapport zodat je het kunt delen",
          "btn_download_report": "Downloaden PDF",
          "btn_download_report_active": "Downloaden...",
          "btn_download_report_hint": "Download het PDF-bestand van je rapport",
          "btn_generate_report": "Rapport genereren",
          "btn_generate_report_hint": "Genereert nieuw rapport",
          "btn_regenerate_report": "Rapport opnieuw genereren",
          "btn_regenerate_report_hint": "Genereert het rapport opnieuw, kan niet ongedaan worden gemaakt",
          "btn_send_report_link_dialog": "Rapport verzenden via dialoogvenster",
          "btn_send_report_link_dialog_hint": "Stuurt de link naar het rapport naar je klant in de dialoog",
          "btn_send_report_link_email": "Rapport per e-mail verzenden",
          "btn_send_report_link_email_active": "Versturen...",
          "btn_send_report_link_email_hint": "Stuurt automatisch een e-mail naar de klant met de link naar het rapport",
          "generate_report_error": "Er was een probleem met het weergeven van dit rapport",
          "generate_report_prompt": "Begin met het genereren van een rapport",
          "report_generated_at": "Dit rapport is gegenereerd op %{date}",
          "report_is_loading": "Gegenereerd rapport laden",
          "report_opened_by_client_at": "Klant opende zijn rapport op %{date}"
        },
        "personal_information": "Persoonlijke informatie",
        "preview": "Voorbeeld",
        "preview_and_send": "Voorbeeld en verzenden",
        "profile_photo": "Profielfoto",
        "program_archived_warning": "Dit programma is gearchiveerd",
        "program_manager_officer": "Programma Manager/Officier",
        "programs": {
          "fields": {
            "archived": "Gearchiveerd",
            "days_after_month_closure": "Dagen na maandafsluiting",
            "description": "Beschrijving",
            "enable_notes": "Opmerkingen inschakelen",
            "end_date": "Einddatum",
            "learning_path": "Leerpad",
            "max_reminder_days": "Maximale herinneringsdagen",
            "minutes_per_client": "Minuten per klant",
            "name": "Naam",
            "questionnaire": "Vragenlijst",
            "reminder_days": "Herinnering dagen",
            "start_date": "Startdatum",
            "title": "Titel"
          },
          "helper_texts": {
            "days_after_month_closure": "Sessielogs gesloten (periode in dagen dat sessielogs kunnen worden gewijzigd na de laatste dag van de maand)",
            "max_reminder_days": "People Analytics: maximaal aantal dagen om te herinneren",
            "minutes_per_client": "Maximale beschikbare tijd per klant (in minuten)",
            "questionnaire": "Opmerking: de vragenlijst voor People Analytics kan slechts eenmaal worden geconfigureerd.",
            "reminder_days": "People Analytics: aantal dagen na volgende herinneringse-mail"
          }
        },
        "prompt": "Goedkeuren of afwijzen",
        "publication": "Publicatie",
        "publications": "Publicaties",
        "questionnaires": {
          "assignments": "Opdrachten",
          "label": "Vragenlijsten"
        },
        "receivers": "Ontvangers",
        "reject": "Weiger",
        "rematch": "Rematch",
        "rematch_client": "Rematch-client",
        "remove": "Verwijder",
        "remove_receiver": "Ontvanger verwijderen",
        "reply": "Antwoord",
        "save": "Opslaan",
        "send_pdf_reports": "PDF-rapporten verzenden",
        "send_pdf_reports_failed": "Kan geen PDF-rapporten versturen, niet alle deelnemers hebben de vragenlijsten ingevuld",
        "send_pdf_reports_success": "PDF-rapporten succesvol verzonden",
        "short_bio": "Korte biografie",
        "toastrs": {
          "copied": "Gekopieerd!",
          "sent": "Verzonden!"
        },
        "unloq_only_contract_fields": {
          "body": "De onderstaande velden worden beheerd door UNLOQ en zijn <b>NIET</b> zichtbaar en bewerkbaar door de medewerkers zelf. Deze velden worden gebruikt in de selectieprocedure van de medewerker.",
          "title": "Velden beheerd door UNLOQ"
        },
        "untranslated": "Ontbrekende vertalingen",
        "use_template": "Wilt u een sjabloon?",
        "validation": {
          "date_of_birth": {
            "not_adult": "Je moet een geboortedatum opgeven die minstens 16 jaar oud is"
          },
          "end_date_after_start_date": "Einddatum moet later zijn dan begindatum",
          "linkedin": {
            "invalid": "Moet een geldige LinkedIn URL zijn"
          },
          "start_date_invalid": "Startdatum is niet geldig",
          "start_date_required": "Startdatum is vereist"
        },
        "work_experience": "Werkervaring",
        "work_experience_duration": "Totaal aantal jaren en uren ervaring met coaching"
      },
      "associate_lists": {
        "add_failed": "Kan niet worden toegevoegd aan de lijst met medewerkers",
        "added": "Toegevoegd aan de lijst met partners"
      },
      "clients": {
        "rematch_failed": "Klant kon niet opnieuw worden gekoppeld, neem contact op met ondersteuning",
        "rematch_success": "Klant succesvol opnieuw gekoppeld"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Naam rekeninghouder",
          "address_line": "Adres",
          "bank_account_number": "Bankrekeningnummer",
          "bank_identification": "Bank identificatie (BIC/SWIFT)",
          "bank_name": "Naam bank",
          "city": "Stad",
          "company": "Bedrijfsnaam",
          "country": "Land",
          "helper_texts": {
            "bank_account_number": "IBAN of ander bankrekeningnummer",
            "bank_identification": "Vereist als het rekeningnummer geen IBAN is",
            "chamber_of_commerce": "Kamer van Koophandel"
          },
          "house_number": "Huisnummer",
          "region": "Regio",
          "zip_code": "Postcode"
        },
        "all": "Alle geregistreerde coaches",
        "associate_status": {
          "active": "Actief",
          "label": "Status",
          "none": "Geen status",
          "offboard": "Offboard",
          "on_hold": "In de wacht"
        },
        "availability": "Beschikbaarheid",
        "avatars": {
          "approved": "Je nieuwe profielfoto is goedgekeurd",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "Er is geen avatar",
            "upload": "Nieuwe avatar uploaden"
          },
          "not_approved_yet": "Je nieuwe profielfoto is nog niet goedgekeurd. We zullen het zo snel mogelijk beoordelen",
          "photo": {
            "label": "Profielfoto",
            "no_photo": "Er is geen profielfoto"
          },
          "preview": "Voorbeeld",
          "rejected": "Je nieuwe profielfoto is geweigerd, upload een andere a.u.b",
          "upload": "Upload nieuwe profielfoto",
          "uploaded": "Er is een nieuwe foto geüpload, we zullen deze zo snel mogelijk bekijken"
        },
        "bio": {
          "approved": "Je nieuwe bio is goedgekeurd",
          "not_approved_yet": "Je nieuwe bio is nog niet goedgekeurd",
          "rejected": "Je nieuwe bio is afgewezen, schrijf een nieuwe",
          "updated": "Je bio is bijgewerkt en wacht op goedkeuring"
        },
        "both": "Beide",
        "cards_view": "De kaarten bekijken",
        "chamber_of_commerce": "Bedrijfsregisternummer",
        "coach_type": "Type medewerker",
        "coach_types": {
          "a": "Senior Coach & Trainer",
          "t": "Senior trainer en facilitator"
        },
        "coaching_level": "Niveau coaching",
        "coaching_levels": {
          "executive": "Uitvoerend",
          "leadership_coaching": "Leiderschapscoaching",
          "top_executive": "Topman",
          "young_talent_professional": "Jong talent professional"
        },
        "commercial_cv": "Commercieel CV",
        "contract_remarks": "Opmerkingen",
        "contract_type": "Soort contract",
        "contracts": {
          "core_team": "Lid kernteam",
          "fixed_hours": "Medewerker met vaste uren",
          "project_basis": "Medewerker op projectbasis"
        },
        "core_team": "Kernteam",
        "cv": {
          "affiliations": "Affiliaties",
          "approach": "Benadering",
          "branch_experiences": "Branche-ervaringen",
          "certifications": "Certificeringen",
          "confirm_copy_cv": "Weet u zeker dat u alle informatie uit uw standaard-cv wilt kopiëren? <br /><br />Let op <b>U KUNT DIT ALLEEN EEN KEER DOEN</b>, daarna verdwijnt de knop!",
          "contract_type": "Soort contract",
          "copy_default_cv": "Uw standaard-cv kopiëren?",
          "copy_from_default_button": "Kopiëren van standaard CV",
          "education": "Onderwijs",
          "examples": {
            "affiliation": {
              "acronym": "Voorbeeld: EMCC",
              "name": "Voorbeeld: EMCC Wereldwijd",
              "number": "Voorbeeld: 100"
            },
            "certification": {
              "end_date": "Voorbeeld: Datum tot certificering geldig was (leeg = onbeperkt)",
              "institution": "Voorbeeld: Internationale Federatie voor Coaching",
              "name": "Voorbeeld: ICF Professioneel Gecertificeerd Coach",
              "start_year": "Voorbeeld: Jaar waarvan de certificering geldig is",
              "topic": "Voorbeeld: Coaching cursus"
            },
            "education": {
              "end_date": "Voorbeeld: Datum tot onderwijs geldig was (leeg = onbeperkt)",
              "field_of_study": "Voorbeeld: Klinische psychologie",
              "school": "Voorbeeld: Harvard",
              "specialisation": "Voorbeeld: Groepsdynamica",
              "start_year": "Voorbeeld: Jaar vanaf wanneer de opleiding geldt"
            },
            "publication": {
              "authors": "Voorbeeld: Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Voorbeeld: Tijdschrift voor Bewustzijn",
              "medium": "Voorbeeld: Televisie",
              "page_range": "Voorbeeld: 201-220",
              "publisher": "Voorbeeld: Routledge",
              "publisher_city": "Voorbeeld: New York",
              "title": "Voorbeeld: Zelfherkenning in het dagelijks leven",
              "url": "Voorbeeld: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Voorbeeld: Te gast bij The Late Show met Stephen Colbert.",
              "volume_number": "Voorbeeld: 5(2)",
              "year": "Voorbeeld: (2022)"
            },
            "publications": {
              "type": "Kies het type publicatie"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acroniem",
              "add": "Een andere affiliatie toevoegen",
              "name": "Naam",
              "number": "Aangesloten nummer"
            },
            "approach": "Korte biografie",
            "approach_current": "Huidige bio",
            "approach_helper_text": "Schrijf je sollicitatie in het Engels",
            "certification": {
              "add": "Nog een certificering toevoegen",
              "end_date": "Einddatum",
              "institution": "Uitgevende organisatie",
              "level": "Niveau",
              "name": "Naam certificaat",
              "start_year": "Startjaar",
              "topic": "Onderwerp / Thema"
            },
            "certification_dropzone": "Drop uw diploma / certificering / getuigenis bestanden hier",
            "certification_dropzone_helpertext": "Alleen PDF-, PNG- of JPG-bestanden kunnen worden geüpload",
            "education": {
              "add": "Nog een opleiding toevoegen",
              "degree": "Graad",
              "end_date": "Einddatum",
              "field_of_study": "Vakgebied",
              "school": "School",
              "specialisation": "Specialisatie",
              "start_year": "Startjaar"
            },
            "occupations": "Beroepen",
            "publication": {
              "add": "Nog een publicatie toevoegen",
              "article": "Artikel",
              "authors": "Auteur(s)",
              "book": "Boek",
              "choose": "Kies type publicatie",
              "journal_title": "Naam tijdschrift",
              "medium": "Medium",
              "page_range": "Pagina bereik",
              "publisher": "Uitgever",
              "publisher_city": "Uitgeverskantoor Stad",
              "title": "Titel",
              "title_article": "Artikel titel",
              "title_book": "Titel boek",
              "url": "Internetverbinding",
              "various": "Diverse",
              "volume_number": "Volumenummer (editienummer)",
              "year": "Jaar"
            },
            "publication_type": "Type publicatie",
            "specializations": "Specialisaties",
            "work_experience": {
              "add": "Nog een werkervaring toevoegen",
              "branch_experience": "Branche-ervaringen",
              "company_name": "Bedrijfsnaam",
              "description": "Werkbeschrijving",
              "end_date": "Einddatum (leeg = onbepaald)",
              "hours_of_experience": "Coaching-uren",
              "role": "Rol / titel",
              "start_date": "Startdatum",
              "years_of_experience": "Jarenlange coachingservaring"
            }
          },
          "headers": {
            "certifications": "Opleidingen/certificeringen"
          },
          "help": {
            "avatars": {
              "help": "Tips voor foto's"
            }
          },
          "intro": {
            "affiliations": "Vermeld alle beroepsverenigingen waarbij je bent aangesloten.",
            "availability": "Hier kunt u uw beschikbaarheid en reisvoorkeuren beheren.",
            "certifications": "Vermeld hier al je licenties & certificaten voor methoden, aanpakken, assessments, tests en andere zaken die te maken hebben met leren, ontwikkelen en begeleidingsactiviteiten. Dit moeten je (beroepsgerichte) opleidingen, trainingen, cursussen en workshops zijn met een officieel certificaat / licentie / keurmerk.",
            "contract_type": "Dit gedeelte is voor intern gebruik en wordt onderhouden door een Programmamanager. Je kunt hier zelf geen aanpassingen maken.",
            "education": "Vermeld hier al je opleidingen en trainingen die je hebt gevolgd (ook die je niet hebt afgerond). Dit moeten je officieel door de overheid erkende opleidingen/kwalificaties/diploma's zijn.",
            "publications": "Vermeld alle publicaties die je hebt geschreven of waaraan je hebt bijgedragen. Let op, voor een uniforme weergave gebruiken we de internationale APA-regels. Voor elk veld geven we een voorbeeld, gebruik deze om elk veld correct in te vullen.",
            "work_experience": "Vermeld hier je relevante werkervaring, niet alleen met betrekking tot coaching & training, maar al je werkervaring."
          },
          "job_experience": "Baan & werkervaring",
          "job_information": "Functie-informatie",
          "level_coaching": "Coachingsniveau",
          "level_ecoaching": "E-coaching niveau",
          "professions": "Beroepen",
          "publications": "Publicaties",
          "sectors": "Sectoren",
          "specializations": "Specialisaties",
          "work_experiences": "Werkervaringen"
        },
        "date_of_birth": "Geboortedatum",
        "default_cv": "Standaard CV",
        "ecoaching_level": "Niveau ecoaching",
        "ecoaching_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Geen"
        },
        "first_name": "Voornaam",
        "group_work": {
          "add": "Nog een groepswerkervaring toevoegen",
          "select": "Selecteer type groepswerk"
        },
        "group_work_location": {
          "label": "Online / offline"
        },
        "group_work_locations": {
          "both": "Beide",
          "offline": "Offline",
          "online": "Online"
        },
        "group_work_type": {
          "label": "Ontwerp / Levering"
        },
        "group_work_types": {
          "both": "Beide",
          "delivery": "Levering",
          "design": "Ontwerp"
        },
        "hours_of_experience": "Het totale aantal uren dat je anderen hebt gecoacht",
        "id_number": "ID-nummer (paspoort of ander ID)",
        "intro": {
          "addresses": "Geef hieronder uw bedrijfsgegevens op. Let op: alleen als je <b>geen</b> IBAN-bankrekeningnummer hebt, is het verplicht om ook het veld 'BIC/SWIFT' in te vullen.",
          "avatars": "Upload je profielfoto. We zijn op zoek naar krachtige portretten waarin de mens centraal staat. Een strakke look & feel zonder de warmte van het menselijke aspect te verliezen. De foto's worden ook gekenmerkt door scherptediepte en het gebruik van natuurlijk licht.<br /><br />Bedenk dat we op zoek zijn naar strakke foto's met weinig felle kleuren of afleidende objecten op de achtergrond.<br /><br />Denk aan je LinkedIn-foto, maar dan een meer ontspannen versie. Je foto moet laten zien wie je bent zonder te vergeten dat dit een zakelijke omgeving is. Vraag uzelf af: welke foto zou u op uw LinkedIn plaatsen vs. welke foto zou u plaatsen als u op vakantie bent? <br /><br />Probeer het volgende te vermijden: Korte broeken, mouwloze of opzichtige topjes, sandalen<br /><br />De foto die je hier uploadt, wordt weergegeven op de pagina \"Ons team\" op de website van UNLOQ en (indien van toepassing) op de websitepagina's van CoachDesks.<br /><br /><b>Opmerking:</b> zodra je de foto hebt geüpload, moet deze door UNLOQ worden goedgekeurd voordat deze zichtbaar wordt op de website van UNLOQ en CoachDesks en als avatar binnen Campus. Zodra de foto is goedgekeurd, verschijnt deze in je profiel.",
          "bio": "Schrijf een korte bio over jezelf. Deze bio wordt toegevoegd aan je CV en zal ook zichtbaar zijn op de <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Ons Team</a> pagina op de UNLOQ website en (indien van toepassing) op de CoachDesks.<br /><br />De volgende eisen worden gesteld:<ul><li>Min 400 tekens</li><li>Max 800 tekens</li><li>Geschreven in derde persoon</li><li>Geschreven in het Engels</li></ul><i>Voorbeeld: Marcel heeft 15 jaar coachingservaring in leiderschapsontwikkeling en transitieprocessen. Hij is geïnteresseerd in onderwerpen die te maken hebben met diversiteit, gelijkheid en inclusie en zijn praktische benadering is gebaseerd op Oosterse en Westerse psychologie. Klanten en collega's zeggen dat hij ruimdenkend en flexibel is, snel ter zake komt en een scherp gevoel heeft voor wat nodig is.</i><br /><br /><b>Noot:</b> zodra je je bio hebt bijgewerkt, moet deze worden goedgekeurd door UNLOQ voordat deze zichtbaar wordt op de website en CoachDesks van UNLOQ. Zodra de bio is goedgekeurd, verschijnt deze in ons profiel.",
          "bio_no_cv_yet": "Geef eerst informatie over je huidige baan voordat je je korte bio schrijft.",
          "group_work": "Vermeld hier je ervaring en voorkeur voor verschillende soorten groepswerk. Je kunt voor elk type groepswerk aangeven of je de voorkeur geeft aan offline, online of beide en of je ervaring hebt in het ontwerpen of geven of beide.",
          "job_information": "Geef informatie over uw huidige baan",
          "languages": "Voeg alle talen die je spreekt toe, samen met het taalvaardigheidsniveau.",
          "personal_information": "In dit deel van je profiel kun je al je persoonlijke basisgegevens invullen. Houd er rekening mee dat sommige van deze gegevens zichtbaar zullen zijn op de <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Ons Team\"</a> pagina op de UNLOQ website en op de CoachDesks. De velden die gemarkeerd zijn als \"alleen voor intern gebruik\" zijn niet zichtbaar voor het publiek."
        },
        "job_title": {
          "select": "Functie"
        },
        "languages": {
          "add": "Voeg nog een taal toe",
          "select": "Selecteer taal",
          "select_level": "Selecteer taalniveau"
        },
        "last_name": "Achternaam",
        "linkedin": "LinkedIn",
        "list_view": "lijstweergave",
        "multiple_answers_possible": "Meerdere antwoorden mogelijk",
        "nationalities": "Nationaliteiten",
        "native_background": "Inheemse achtergrond / wortels",
        "organization_name": "Naam organisatie",
        "phone_number": "Telefoonnummer",
        "place_of_birth": "Geboorteplaats",
        "profile": "Profiel",
        "remarks": {
          "add": "Nieuwe opmerking toevoegen",
          "add_button": "Opmerking toevoegen",
          "added": "Opmerking succesvol toegevoegd",
          "all_remarks": "Alle opmerkingen",
          "complaint_remark": "Klacht",
          "complaint_remarks": "Klachten",
          "compliment_remark": "Compliment",
          "compliment_remarks": "Complimenten",
          "delete_conformation": "Weet je zeker dat je deze opmerking wilt verwijderen?",
          "deleted": "Opmerking succesvol verwijderd",
          "edit": "Opmerking bewerken",
          "general_remark": "Algemene opmerking",
          "general_remarks": "Algemene opmerkingen",
          "intro": "Hier kunt u opmerkingen over coaches lezen en schrijven.",
          "no_remarks": "Er is nog geen %{remark_type}. U kunt er een toevoegen door hieronder op de knop 'Nieuwe opmerking toevoegen' te klikken.",
          "title": "Opmerkingen",
          "update_button": "Opmerking bijwerken",
          "updated": "Opmerking met succes bijgewerkt"
        },
        "residence_country": "Land van verblijf",
        "search_in": "Zoeken in",
        "secondary_phone_number": "Alternatief telefoonnummer",
        "sectors": {
          "humanitarian": "Humanitair",
          "private_sector": "Privé",
          "public_sector": "Publiek"
        },
        "select_one_answer": "Selecteer een antwoord",
        "timezone": "Tijdzone",
        "training_level": "Niveau facilitering",
        "training_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Geen"
        },
        "travel": {
          "label": "Reizen",
          "no_travel": "Geen",
          "yes_to_conflict": "Ja, ook naar conflictgebieden",
          "yes_travel": "Ja"
        },
        "unloq": "UNLOQ associates",
        "unloq_only": "Alleen UNLOQ associates?",
        "years_of_experience": "Het aantal jaren dat u al coach bent"
      },
      "filters": {
        "branch_experiences": "Branche-ervaringen",
        "certifications": "Certificeringen",
        "countries": "Landen",
        "hours_of_experience": "Minimum aantal uren ervaring",
        "job_title": "Functie",
        "languages": "Talen",
        "miscellaneous": "Diverse",
        "name_or_email": "Naam of e-mail",
        "organization": "Organisatie",
        "professions": "Beroepen",
        "programs": "Programma's",
        "remarks": "Opmerkingen",
        "specializations": "Specialisaties",
        "timezones": "Tijdzones",
        "years_of_experience": "Minimum aantal jaren ervaring"
      },
      "select": {
        "associate_list": "Selecteer geassocieerde lijst",
        "coach": "Selecteer coach",
        "program": "Selecteer programma"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Welkom op de pagina voor het bewerken van profielen!",
            "text_2": "In dit tabblad moeten je belangrijkste gegevens worden ingevuld.",
            "text_3": "De informatie die je hier invult, wordt gebruikt om je aan een klant te koppelen en om je curriculum vitae te genereren."
          },
          "step_commercial_cv": {
            "text_1": "Het tabblad <b>%{commercial_cv}</b> is vergelijkbaar met het tabblad Default CV, behalve dat de informatie die u hier invoert specifiek bedoeld is voor commerciële klanten."
          },
          "step_copy": {
            "text_1": "Je kunt de <b>%{button_name}</b> knop gebruiken als er nog geen commerciële CV is gemaakt.",
            "text_2": "De knop kopieert alle informatie van je standaard CV waarna je bepaalde onderdelen kunt wijzigen of verwijderen."
          },
          "step_default_cv": {
            "text_1": "In dit tabblad wordt je standaard curriculum vitae samengesteld.",
            "text_2": "Vul deze gegevens zo volledig mogelijk in, zodat UNLOQ jouw capaciteiten beter kan afstemmen op onze klanten.",
            "text_3": "We gebruiken deze gegevens ook om automatisch CV-documenten te genereren.",
            "text_4": "De gegenereerde CV-documenten worden alleen intern of met onze klanten gedeeld."
          },
          "step_help": {
            "text_1": "Deze knop opent deze Help-tour opnieuw. Dus als je later uitleg wilt, klik dan op deze knop."
          }
        }
      }
    }
  }
}